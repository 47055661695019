.accordion-block {
    position: relative;
    padding: rem-calc(80 0 100);
    @include bp(tabletmd) {
        padding: rem-calc(60 0);
    }
    @include bp(tabletsm) {
        padding: rem-calc(30 0);
    }
    .heading {
        max-width: rem-calc(1080);
        margin: 0 auto 65px;
        width: 100%;
        padding: rem-calc(0 10 0 0);
    }
    .accordion-block-wrap {
        max-width: 1240px;
        margin: 0 auto;
        width: 100%;
        .accordion {
            .card {
                border: 0;
                margin-bottom: rem-calc(50);
                background-color: $white;
                -webkit-box-shadow: 0px 1px 3px 1px rgba(39,37,37,0.16);
                -moz-box-shadow: 0px 1px 3px 1px rgba(39,37,37,0.16);
                box-shadow: 0px 1px 3px 1px rgba(39,37,37,0.16);
                border-radius: 0;
                @include bp(tabletXl) {
                    margin-bottom: rem-calc(30);
                }
                .card-header {
                    background-color: transparent;
                    padding: 0;
                    margin: 0;
                    border-radius: 0;
                    border: 0;
                    h2 {
                        font-size: 0;
                        position: relative;
                        .btn-link {
                            padding: 0;
                            font-size: rem-calc(28);
                            border: 0;
                            border-radius: 10px;
                            background-color: transparent;
                            display: block;
                            padding: rem-calc(32 0 32 75);
                            width: 100%;
                            letter-spacing: 0;
                            text-align: left;
                            text-transform: inherit;
                            font-weight: $mediumdark;
                            color: $red;
                            @include bp(tabletXl) {
                                font-size: rem-calc(18);
                                padding: rem-calc(15 20 15 60);
                            }
                            @include bp(tabletsm) {
                                font-size: rem-calc(16);
                            }
                            &:before {
                                content: "-";
                                background-color: transparent;
                                width: 40px;
                                height: 40px;
                                position: absolute;
                                left: 20px;
                                top: 48%;
                                border-radius: 50%;
                                transform: translateY(-50%);
                                -webkit-transform: translateY(-50%);
                                -ms-transform: translateY(-50%);
                                color: #54565a;
                                border: 1px solid #54565a;
                                font-size: 45px;
                                font-weight: 300;
                                text-align: center;
                                line-height: 40px;
                                font-family: inherit;
                                padding: 0 11px;
                                @include bp(tabletXl) {
                                    width: 25px;
                                    height: 25px;
                                    font-size: 30px;
                                    line-height: 23px;
                                    padding: 0 7px;
                                }
                            }
                            &.collapsed {
                                background-color: transparent;
                                color: $red;
                                &:before {
                                    content: "+";
                                    background-color: transparent;
                                    width: 40px;
                                    height: 40px;
                                    position: absolute;
                                    right: 20px;
                                    top: 48%;
                                    border-radius: 50%;
                                    transform: translateY(-50%);
                                    -webkit-transform: translateY(-50%);
                                    -ms-transform: translateY(-50%);
                                    color: $gray;
                                    border: 1px solid $gray;
                                    font-size: rem-calc(30);
                                    font-weight: $normal;
                                    text-align: center;
                                    line-height: 40px;
                                    font-family: inherit;
                                    padding: 0 11px;
                                    @include bp(tabletXl) {
                                        width: 25px;
                                        height: 25px;
                                        font-size: 22px;
                                        line-height: 23px;
                                        padding: 0 5px;
                                    }
                                }
                            }
                        }
                    }
                }
                .card-body {
                    border: 0;
                    padding: rem-calc(0 30 38 74);
                    @include bp(tabletXl) {
                        padding: rem-calc(0 30 30 60);
                    }
                    p {
                        margin-bottom: rem-calc(6);
                        text-align: left;
                    }
                    ul {
                        padding: rem-calc(10 0 20 20);
                        li {
                            color: $gray;
                            font-size: rem-calc(20);
                            margin: rem-calc(0 0 5 0);
                            @include bp(tabletXl) {
                                font-size: rem-calc(18);
                            }
                            @include bp(tabletsm) {
                                font-size: rem-calc(16);
                            }
                            a {
                                color: #1362a2;
                            } 
                        }
                    }
                }
            }
        }
    }
}