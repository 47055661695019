.stickHeader {
    header {
        -webkit-box-shadow: 0 7px 13px 0 rgba(16, 17, 19,0.2);
        -moz-box-shadow: 0 7px 13px 0 rgba(16, 17, 19,0.2);
        box-shadow: 0 7px 13px 0 rgba(16, 17, 19,0.2);
    }
}
.home {
    header {
        .container {
            max-width: rem-calc(1323);
            @include bp(lg) {
                max-width: 100%;
            }
            @include bp(tabletmd) {
                position: inherit;
            }
        }
    }
}
header {
    width: 100%;
    background-color: $white;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 9;
    .container {
        @include bp(xl) {
            max-width: 100%;
        }
        @include bp(tabletmd) {
            position: inherit;
        }
    }
    .main-header {
        .logo {
            margin: rem-calc(10 0 0 4);
            @include bp(lg) {
                margin: rem-calc(0);
            }
            a {
                img {
                    max-width: rem-calc(106);
                    width: 100%;
                    @include bp(tabletXl) {
                        max-width: rem-calc(90);
                    }
                }
            }
        }
        .header-inherit {
            @include bp(tabletmd) {
                position: inherit;
            }
            nav {
                padding: rem-calc(0 0 0 18);
            }
        }
    }
}