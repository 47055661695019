.subpages-cards {
    position: relative;
    padding: rem-calc(0 0 85 0);
    @include bp(tabletXl) {
        padding: rem-calc(0 0 60 0);
    }
    @include bp(tabletsm) {
        padding: rem-calc(0 0 40 0);
    }
    .subpages-cards-list {
        margin-bottom: rem-calc(38);
        .subpages-cards-wrap {
            a {
                display: inline-block;
                vertical-align: middle;
                height: 100%;
                width: 100%;
                -webkit-box-shadow: 0px 1px 3px 1px rgba(39, 37, 37, 0.26);
                -moz-box-shadow: 0px 1px 3px 1px rgba(39, 37, 37, 0.26);
                box-shadow: 0px 1px 3px 1px rgba(39, 37, 37, 0.26);
                padding-bottom: rem-calc(0);
                background-color: $white;
                @include transition(0.4s, all, ease-in-out);
                &:hover {
                    -webkit-box-shadow: 0px 3px 5px 3px rgba(39, 37, 37, 0.26);
                    -moz-box-shadow: 0px 3px 5px 3px rgba(39, 37, 37, 0.26);
                    box-shadow: 0px 3px 5px 3px rgba(39, 37, 37, 0.26);
                }
                &:focus {
                    outline: none;
                }
                .subpages-cards-bg-wrap {
                    position: relative;
                    z-index: 1;
                    overflow: hidden;
                    -webkit-transition:-webkit-transform 0.7s linear;
                    transition:-webkit-transform 0.7s linear;
                    transition:transform 0.7s linear;
                    transition:transform 0.7s linear, -webkit-transform 0.7s linear;
                    @include transition(0.5s, all, ease-in-out);
                    &:hover {
                        .subpages-cards-bg {
                            -webkit-transform:scale(1.2);
                            -ms-transform:scale(1.2);
                            transform:scale(1.2);
                            -webkit-transition-duration:2s;
                            transition-duration:2s;
                        }
                    }
                    .subpages-cards-bg {
                        padding-bottom: 59%;
                        background-position: center center;
                        background-repeat: no-repeat;
                        background-size: cover;
                        position: relative;
                        z-index: 1;
                        @include transition(0.5s, all, ease-in-out);
                    }
                }
                .subpages-cards-cont {
                    padding: rem-calc(27 27 38);
                    @include bp(tabletXl) {
                        padding: rem-calc(20);
                    }
                    h3 {
                        i {
                            font-style: normal;
                        }
                    }
                }
            }
        }
    }
}