.projects-carousel {
    position: relative;
    padding: rem-calc(80 0 60);
    @include bp(tabletXl) {
        padding: rem-calc(60 0 60);
    }
    @include bp(tabletsm) {
        padding: rem-calc(40 0 40);
    }
    .heading {
        margin: rem-calc(0 0 63 0);
        @include bp(tabletXl) {
            margin: rem-calc(0 0 30 0);
        }
        h2 {
            color: $white;
        }
    }
    .projects-carousel-wrap {
        overflow: hidden;
        padding-bottom: rem-calc(115);
        @include bp(tabletXl) {
            padding-bottom: rem-calc(70);
        }
        .slick-list {
            margin: 0 -4px;
        }
        .projects-carousel-list {
            margin: 0 4px;
            &:focus {
                outline: none;
            }
            .projects-carousel-bg {
                height: rem-calc(470);
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;
                position: relative;
                overflow: hidden;
                cursor: pointer;
                @include bp(lg) {
                    height: rem-calc(470);
                }
                @include bp(tabletXl) {
                    height: rem-calc(380);
                }
                &:focus {
                    outline: none;
                }
                @include transition(0.5s, all, ease-in-out);
                &:hover {
                    .carousel-overlay {
                        top: 0;
                    }
                }
                .carousel-overlay {
                    position: absolute;
                    top: 100%;
                    width: 100%;
                    height: 100%;
                    &:focus {
                        outline: none;
                    }
                    @include transition(0.5s, all, ease-in-out);
                    &:before {
                        content: "";
                        background-color: rgba(5,2,2,0.9);
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                    }
                    .carousel-cont-wrap {
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        justify-content: center;
                        height: 100%;
                        padding: 0 22px;
                        &:focus {
                            outline: none;
                        }
                        .carousel-cont {
                            position: relative;
                            text-align: center;
                            h3 {
                                color: $white;
                                margin: rem-calc(0 0 7 0);
                                @include bp(lg) {
                                    font-size: rem-calc(22);
                                    line-height: normal;
                                }
                                @include bp(tabletXl) {
                                    font-size: rem-calc(18);
                                }
                            }
                            span {
                                color: $white;
                                font-size: rem-calc(18);
                                font-weight: $mediumdark;
                                display: block;
                                @include bp(tabletXl) {
                                    font-size: rem-calc(14);
                                    display: block;
                                }
                            }
                            p {
                                color: $white;
                                margin: rem-calc(13 0 0);
                                font-size: rem-calc(16);
                                font-weight: $medium;
                                line-height: rem-calc(29);
                                br {
                                    display: inline-block;
                                    vertical-align: top;
                                }
                                @include bp(tabletXl) {
                                    font-size: rem-calc(14);
                                    margin: rem-calc(10 0 0);
                                    line-height: rem-calc(24);
                                }
                            }
                        }
                    }
                }
            }
        }
        .slick-prev {
            left: -60px;
            z-index: 1;
            width: 45px;
            height: 45px;
            bottom: 8px;
            top: inherit;
            transform: translateY(0);
            right: 0;
            margin: 0 auto;
            @include bp(tabletXl) {
                left: -40px;
                width: 30px;
                height: 30px;
            }
            &:before {
                content: "";
                background-image: url(../images/slider-prev-icon.svg);
                background-position: center center;
                background-repeat: no-repeat;
                background-size: auto;
                width: rem-calc(45);
                height: rem-calc(45);
                position: absolute;
                left: 0px;
                top: 0;
                z-index: 1;
                opacity: 1;
                @include bp(tabletXl) {
                    width: 30px;
                    height: 30px;
                }
            }
        }
        .slick-next {
            right: -76px;
            z-index: 1;
            width: 45px;
            height: 45px;
            bottom: 8px;
            top: inherit;
            transform: translateY(0);
            left: 0;
            margin: 0 auto;
            @include bp(tabletXl) {
                right: -40px;
                width: 30px;
                height: 30px;
            }
            &:before {
                content: "";
                background-image: url(../images/slider-next-icon.svg);
                background-position: center center;
                background-repeat: no-repeat;
                background-size: auto;
                width: rem-calc(45);
                height: rem-calc(45);
                position: absolute;
                left: 0px;
                top: 0;
                z-index: 1;
                opacity: 1;
                @include bp(tabletXl) {
                    width: 30px;
                    height: 30px;
                }
            }
        }
    }
}