body img.gform_ajax_spinner {
    margin: 0 0 0 3px;
}
/*Wrapper*/
.home  {
    .wrapper {
        &:before {
            display: none;
        }
    }
}
body {
    &.menu-open {
        overflow: hidden;
    }
}
.hero-content {
    @include bp(lg) {
        height: auto;
        max-width: 100%;
    }
    @include bp(tabletsm) {
        height: auto;
        max-width: 100%;    
    }
}
.wrapper {
    width: 100%;
    position: relative;
    overflow: hidden;
    .banner-top-shap {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        overflow: hidden;
        z-index: -1;
        &:before {
            content: "";
            background-image: url(../images/gradiant.png);
            width: 100%;
            height: 100%;
            background-position: top center;
            background-repeat: no-repeat;
            background-size: auto;
            position: absolute;
            left: 0;
            top: -600px;
            @include bp(tabletsm) {
                top: 0;
            }
        }
        &:after {
            content: "";
            background-image: url(../images/pattern.png);
            width: 850px;
            height: 600px;
            background-position: bottom right;
            background-repeat: no-repeat;
            background-size: cover;
            position: absolute;
            right: -300px;
            top: -70px;
            @include bp(lg) {
                right: -420px;
                top: -40px;
            }
            @include bp(tabletXl) {
                width: 700px;
                height: 560px;
            }
            @include bp(tabletsm) {
                width: 600px;
                height: 540px;
            }
        }
    }
}    

/*Share Blog Button*/
.at4-share.addthis_32x32_style {
    a {
        &.at-share-btn {
            .at-icon-wrapper {
                svg {
                    color: $white;
                }
            }
        }
    }
}

.pagination-list {
    display: block;
    width: 100%;
    text-align: center;
    .pagination-wrap {
        .page-numbers {
            font-size: 20px;
            margin: 0 5px;
            &.prev {
                opacity:0;
                display: none;
            }
            &.next {
                opacity:0;
                display: none;
            }
            color: $gray;
            &.current {
                color: $red;
            }
        }
    }
}

.slick-list {
    .item {
        &:focus {
            outline: none;
        }
    }
}
/*Common Video Css Start*/
.videobg_child {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    &:before {
        content: "";
        background-color: rgba(21,39,55,0.67);
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
    video {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

/*Overlay Css Start*/
.bgoverlay:before {
    content: "";
    background-color: inherit;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0.6;
}

/*Sub Menu Under Line */
.menu-open-body {
    header {
        border-bottom: 1px solid $dark-black;
    }
}

/*Image Hover Effect*/
@-webkit-keyframes circle {
    0% {
        opacity: 1;
    }
    40% {
        opacity: 1;
    }
    100% {
        width: 200%;
        height: 200%;
        opacity: 0;
    }
}
@keyframes circle {
    0% {
        opacity: 1;
    }
    40% {
        opacity: 1;
    }
    100% {
        width: 200%;
        height: 200%;
        opacity: 0;
    }
}

.subscribe-form ::-webkit-input-placeholder { 
    color: $white!important;
    opacity: 1!important;
}

.subscribe-form :-ms-input-placeholder { 
    color: $white!important;
    opacity: 1!important;
}

.subscribe-form ::placeholder {
    color: $white!important;
    opacity: 1!important;
}

.plan-your-event-form ::-webkit-input-placeholder { 
    color: #c6c5c5!important;
    opacity: 1!important;
}

.plan-your-event-form :-ms-input-placeholder { 
    color: #c6c5c5!important;
    opacity: 1!important;
}

.plan-your-event-form ::placeholder {
    color: #c6c5c5!important;
    opacity: 1!important;
}

.plan-your-event .plan-your-event-form ::-webkit-input-placeholder { 
    color: $gray!important;
    opacity: 1!important;
}

.plan-your-event .plan-your-event-form :-ms-input-placeholder { 
    color: $gray!important;
    opacity: 1!important;
}

.plan-your-event .plan-your-event-form ::placeholder {
    color: $gray!important;
    opacity: 1!important;
}

.contact-us-form ::-webkit-input-placeholder { 
    color: #c6c5c5!important;
    opacity: 1!important;
}

.contact-us-form :-ms-input-placeholder { 
    color: #c6c5c5!important;
    opacity: 1!important;
}

.contact-us-form ::placeholder {
    color: #c6c5c5!important;
    opacity: 1!important;
}


::-webkit-input-placeholder { 
    color: #fff!important;
    opacity: 1!important;
}

:-ms-input-placeholder { 
    color: #fff!important;
    opacity: 1!important;
}

::placeholder {
    color: #fff!important;
    opacity: 1!important;
}


/*Radio Button*/
.plan-your-event-form [type="radio"]:checked,
.plan-your-event-form [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
.plan-your-event-form [type="radio"]:checked + label,
.plan-your-event-form [type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 0px!important;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #54565a;
    font-size: rem-calc(18);
    font-weight: $bold;
}
.plan-your-event-form [type="radio"]:checked + label:before,
.plan-your-event-form [type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 24.5px;
    height: 24.5px;
    border: 1px solid #A2A9AD;
    border-radius: 100%;
    background: #fff;
}
.plan-your-event-form [type="radio"]:checked + label:after,
.plan-your-event-form [type="radio"]:not(:checked) + label:after {
    content: '';
    width: 100%;
    height: 100%;
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    z-index: -1;
    border: 1px solid #54565a;
}
.plan-your-event-form [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
.plan-your-event-form [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}


[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 35px!important;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #7C878D;
    font-size: rem-calc(18);
    font-weight: $bold;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 24.5px;
    height: 24.5px;
    border: 1px solid #A2A9AD;
    border-radius: 100%;
    background: #fff;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 11.5px;
    height: 11.5px;
    background: #A2A9AD;
    position: absolute;
    top: 6.5px;
    left: 6.5px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}


.contact-us-form [type="checkbox"]:not(:checked) + label:before,
.contact-us-form [type="checkbox"]:checked + label:before {
    content: '';
    position: absolute;
    left: 1px; 
    top: 5px;
    width: 17px; 
    height: 17px;
    border: 1px solid #fff;
    background: transparent;
    border-radius: 0px;
}
.contact-us-form [type="checkbox"]:not(:checked) + label:after,
.contact-us-form [type="checkbox"]:checked + label:after {
    content: '\2713\0020';
    position: absolute;
    top: 8px;
    left: 3px;
    font-size: 16px;
    line-height: 0.8;
    color: #fff;
    transition: all .2s;
}
.contact-us-form [type="checkbox"]:not(:checked) + label, .contact-us-form [type="checkbox"]:checked + label {
    color: $white;
    margin: 9px 0 0 0;
    font-size: 15px;
    @include bp(tabletmd) {
        font-size: 13px;
    }
    a {
        color: $white;
        text-decoration: underline;    
        font-size: 15px;
        &:hover {
            color: $red;
        }
    }
}

/*checkbox Start*/
[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
    position: absolute;
    left: -9999px;
}
[type="checkbox"]:not(:checked) + label,
[type="checkbox"]:checked + label {
    position: relative;
    padding-left: 1.95em!important;
    cursor: pointer;
    color: $gray;
    font-size: 18px;
    margin: 0;
}

[type="checkbox"]:not(:checked) + label:before,
[type="checkbox"]:checked + label:before {
    content: '';
    position: absolute;
    left: 5px; top: 5px;
    width: 17px; 
    height: 17px;
    border: 2px solid #7B868C;
    background: transparent;
    border-radius: 0px;
}
[type="checkbox"]:not(:checked) + label:after,
[type="checkbox"]:checked + label:after {
    content: '\2713\0020';
    position: absolute;
    top: 7px; 
    left: 8px;
    font-size: 14px;
    line-height: 0.8;
    color: $gray;
    transition: all .2s;
}
[type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0);
}
[type="checkbox"]:checked + label:after {
    opacity: 1;
    transform: scale(1);
}

[type="checkbox"]:disabled:not(:checked) + label:before,
[type="checkbox"]:disabled:checked + label:before {
    box-shadow: none;
    border-color: #bbb;
    background-color: #ddd;
}
[type="checkbox"]:disabled:checked + label:after {
    color: #999;
}
[type="checkbox"]:disabled + label {
    color: #aaa;
}
