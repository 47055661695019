.blog {
    .inner-hero-banner {
        padding: 60px 0 67px 0;
        @include bp(tabletsm) {
            padding: rem-calc(40 0);
        }
        .inner-banner-cont {
            padding: 0 0 0 13px;
        }
    }
}
.parent-pageid-18 {
    .inner-hero-banner {
        height: 462px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 0 0 0 10px;
        position: relative;
        @include bp(lg) {
            height: 380px;
        }
        @include bp(tabletXl) {
            height: 320px;
        }
        @include bp(tabletsm) {
            height: 300px;
        }
        @include bp(sm) {
            height: 270px;
        }
        .hero-content {
            height: auto;
            position: relative;
            top: 50%;
            z-index: 1;
            width: 100%;
            transform: translateY(-50%);
            -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            left: 0;
            text-align: left;
            align-items: end;
            @include bp(lg) {
                max-width: 1000px;
            }
            @include bp(tabletmd) {
                max-width: 760px;
            }
            @include bp(tabletsm) {
                max-width: 550px;
            }
            .inner-banner-cont {
                padding: rem-calc(0 0 0 0);
                position: relative;
                z-index: 1;
                color: $white;
                font-size: rem-calc(24);
                font-weight: $mediumdark;
                @include bp(xl) {
                    padding: rem-calc(0 0 0 0);
                }
                @include bp(lg) {
                    padding: rem-calc(0 0 0 0);
                }
                @include bp(tabletXl) {
                    font-size: rem-calc(20);
                }
                @include bp(tabletsm) {
                    font-size: rem-calc(18);
                }
                @include bp(sm) {
                    font-size: rem-calc(16);
                }
                font-weight: $mediumdark;
                h1 {
                    color: $white;
                }
                span {
                    color: $white;
                    font-size: rem-calc(24);
                    font-weight: $mediumdark;
                    display: inline-block;
                    vertical-align: top;
                    margin: 0 0 8px 0;
                    @include bp(tabletXl) {
                        font-size: rem-calc(20);
                    }
                    @include bp(tabletsm) {
                        font-size: rem-calc(18);
                    }
                    @include bp(sm) {
                        font-size: rem-calc(16);
                    }
                    a {
                        span {
                            color: $white;
                            font-size: rem-calc(24);
                            font-weight: $mediumdark;
                            display: inline-block;
                            vertical-align: top;
                            margin: 0 0 8px 0;
                            @include bp(tabletXl) {
                                font-size: rem-calc(20);
                            }
                            @include bp(tabletsm) {
                                font-size: rem-calc(18);
                            }
                            @include bp(sm) {
                                font-size: rem-calc(16);
                            }
                        }
                    }
                }
            }
        }
    }
}

.inner-hero-banner {
    position: relative;
    padding: rem-calc(42 0 50);
    @include bp(tabletsm) {
        padding: rem-calc(30 0 30);
    }
    &.error-page-block {
        padding: 100px 0;
        @include bp(tabletsm) {
            padding: 60px 0;
        }
        &:before {
            content: "";
            background-color: rgba(10,10,50,0.5);
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }
        .error-page-block-wrap {
            .error-page-wrap{
                h1 {
                    color: $white;
                }
                span {
                    color: $white;
                }
                p {
                    color: $white;
                }
            }
        }
    }
    .inner-banner-cont {
        padding: rem-calc(0 0 0 0);
        text-align: left;
        display: inline-block;
        vertical-align: top;
        color: $gray;
        font-size: rem-calc(24);
        font-weight: $mediumdark;
        margin: rem-calc(0 0 9 0);
        @include bp(tabletXl) {
            font-size: rem-calc(20);
        }
        @include bp(tabletsm) {
            font-size: rem-calc(16);
            line-height: normal;
            margin: 0;
        }
        @include bp(lg) {
            padding: rem-calc(0 0 0 0);
        }
        span {
            display: inline-block;
            vertical-align: top;
            color: $gray;
            font-size: rem-calc(24);
            font-weight: $mediumdark;
            margin: rem-calc(0 0 9 0);
            @include bp(tabletXl) {
                font-size: rem-calc(20);
            }
            @include bp(tabletsm) {
                font-size: rem-calc(16);
                line-height: normal;
                margin: 0;
            }
        }
        h1 {
            padding: rem-calc(0 60 0 0);
            @include bp(xl) {
                padding: rem-calc(0 0 0 0);
            }
        }
        p {
            font-size: rem-calc(20);
            line-height: rem-calc(30);
            @include bp(lg) {
                font-size: rem-calc(18);
                line-height: 1.5;
            }
            @include bp(tabletXl) {
                font-size: rem-calc(16);
            }
            br {
                display: block;
                margin: 0 0 10px 0;
            }
        }
    }
}