.destination-gallery {
    position: relative;
    padding: rem-calc(110 0 148);
    @include bp(tabletXl) {
        padding: rem-calc(80 0);
    }
    @include bp(tabletXl) {
        padding: rem-calc(60 0);
    }
    @include bp(tabletsm) {
        padding: rem-calc(40 0);
    }
    .heading {
        margin: 0 0 50px 0;
    }
    .destination-gallery-wrap {
        display: flex;
        flex-wrap: wrap;
        .destination-gallery-bg {
            display: flex;
            flex-wrap: wrap;
            &.two-col {
                width: 50%;
                padding: 0px 0px 10px 10px;
                @include bp(xsm) {
                    width: 100%;
                    padding: rem-calc(0 0 10 0);
                }
                .destination-gallery-bg-wrap {
                    width: 100%;
                    height: 100%;
                    .bg {
                        padding-bottom: 63.25%;
                        background-position: center center;
                        background-repeat: no-repeat;
                        background-size: cover;
                        width: 100%;
                        position: relative;
                        @include transition(0.5s, all, ease-in-out);
                        @include bp(tabletsm) {
                            padding-bottom: 80%;
                        }

                    }
                }
            }
        }
        .destination-gallery-bg {
            &.three-col {
                width: 33.33%;
                padding: 3px 0 10px 10px;
                @include bp(xsm) {
                    width: 100%;
                    padding: rem-calc(0 0 10 0);
                }
                .destination-gallery-bg-wrap {
                    width: 100%;
                    height: 100%;
                    .bg {
                        padding-bottom: 96.25%;
                        background-position: center center;
                        background-repeat: no-repeat;
                        background-size: cover;
                        width: 100%;
                        position: relative;
                        @include transition(0.5s, all, ease-in-out);
                        @include bp(tabletsm) {
                            padding-bottom: 80%;
                        }
                    }
                }
            }
        }
    }
    .destination-gallery-btn {
        margin: rem-calc(51 0 0 0);
        @include bp(tabletsm) {
            margin: rem-calc(20 0 0 0);
        }
        a {
            border: 1px solid $red;
            color: $red;
            &:hover {
                background-color: $red;
                color: $white;
                border-color: $red;
            }
            &:focus {
                background-color: $red;
                color: $white;
                border-color: $red;
            }
        }
    }
}