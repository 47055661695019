#fifty-fifty-block-2 {
    padding-top: 0;
}
.fifty-fifty-block {
    position: relative;
    padding: rem-calc(106px 0 100px);
    @include bp(tabletXl) {
        padding: rem-calc(60 0);
    }
    @include bp(tabletsm) {
        padding: rem-calc(40 0);
    }
    .container {
        @include bp(tabletmd) {
            max-width: 100%;
        }   
    }
    &.event-fifty-fifty-block {
        padding: rem-calc(50 0 45);
        @include bp(tabletXl) {
            padding: rem-calc(40 0);
        }
        @include bp(tabletsm) {
            padding: rem-calc(30 0);
        }
        .fifty-fifty-block-list {
            .fifty-fifty-bg-wrap {
                .fifty-fifty-bg {
                    padding-bottom: 73.25%;
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    position: relative;
                    overflow: hidden;
                    cursor: pointer;
                    @include transition(0.5s, all, ease-in-out);
                }
            }
        }
    }
    &.fifty-fifty-border {
        //border-bottom: 1px solid #55565a;
        &:before {
            content: "";
            background-color: #55565a;
            height: 1px;
            width: 98.3%;
            position: absolute;
            left: 0;
            bottom: 0;
            right: 0;
            margin: 0 auto;
        }
    }
    &.destinations-single-fifty {
        padding: rem-calc(0 0 95);
        @include bp(tabletXl) {
            padding: rem-calc(0 0 60);
        }
        @include bp(tabletsm) {
            padding: rem-calc(0 0 40);
        }
        .fifty-fifty-block-list {
            &.right-side-block {
                .fifty-fifty-bg-wrap {
                    .fifty-fifty-bg {
                        padding-bottom: 83.30%;
                    }
                }
                .fifty-fifty-cont-wrap {
                    padding: rem-calc(7 20 0 10);
                    @include bp(tabletsm) {
                        padding: rem-calc(7 0 0 0);
                    }
                    .fifty-fifty-cont {
                        @include bp(tabletsm) {
                            padding: rem-calc(20 0 0 0);
                        }
                        ol {
                            padding: 0 0 0 16px;
                            li {
                                
                            }
                        }
                        ul {
                            li {
                                position: relative;
                                list-style-type: none;
                                margin: 15px 0 16px 0;
                                padding: 0 30px 0 39px;
                                font-size: rem-calc(18);
                                line-height: normal;
                                @include bp(lg) {
                                    padding: 0 10px 0 30px;
                                    font-size: rem-calc(18);
                                }
                                @include bp(tabletXl) {
                                    font-size: rem-calc(16);
                                }
                                &:before {
                                    content: "";
                                    background-image: url(../images/right-list-icon.svg);
                                    background-repeat: no-repeat;
                                    background-size: cover;
                                    background-position: center center;
                                    width: 25px;
                                    height: 22px;
                                    position: absolute;
                                    left: 0;
                                    top: 0;
                                    @include bp(lg) {
                                        content: "";
                                        width: 20px;
                                        height: 18px;
                                        top: 2px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .fifty-fifty-block-list {
        &.right-side-block {
            .right-side-cont {
                order: 1;
                @include bp(tabletsm) {
                    order: 2;
                }
            }
            .right-side-bg {
                order: 2;
                @include bp(tabletsm) {
                    order: 1;
                }
                .fifty-fifty-bg-wrap {
                    padding: rem-calc(0 8 0 0);
                }
            }
            .fifty-fifty-cont-wrap {
                padding: rem-calc(0);
                .fifty-fifty-cont {
                    @include bp(tabletsm) {
                        padding: rem-calc(20 0 0 0);
                    }
                    h3 {
                        margin: rem-calc(0 0 8 0);
                    }
                    p {
                        margin-bottom: rem-calc(9);
                    }
                    .fifty-fifty-btn {
                        margin: rem-calc(38 0 0 0);
                    }
                    ul {
                        li {
                            position: relative;
                            list-style-type: none;
                            margin: 15px 0 16px 0;
                            padding: 0 30px 0 39px;
                            @include bp(lg) {
                                padding: 0 10px 0 30px;
                                font-size: rem-calc(18);
                            }
                            @include bp(tabletXl) {
                                font-size: rem-calc(16);
                            }
                            &:before {
                                content: "";
                                background-image: url(../images/right-list-icon.svg);
                                background-repeat: no-repeat;
                                background-size: cover;
                                background-position: center center;
                                width: 25px;
                                height: 22px;
                                position: absolute;
                                left: 0;
                                top: 0;
                                @include bp(lg) {
                                    content: "";
                                    width: 20px;
                                    height: 18px;
                                    top: 2px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .fifty-fifty-bg-wrap {
            padding: rem-calc(0 0 0 0);
            .fifty-fifty-bg {
                padding-bottom: 60.25%;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;
                position: relative;
                cursor: pointer;
                @include transition(0.5s, all, ease-in-out);
            }
        }
        .fifty-fifty-cont-wrap {
            padding: rem-calc(0 12 0 18);
            @include bp(lg) {
                padding: rem-calc(0);
            }
            .fifty-fifty-cont {
                @include bp(tabletsm) {
                    padding: rem-calc(20 0 0 0);
                }
                h2 {
                    font-size: rem-calc(28);
                    line-height: rem-calc(38);
                    font-weight: $mediumdark;
                    color: $red;
                    @include bp(tabletXl) {
                        font-size: rem-calc(26);
                        line-height: 1.2;
                        margin: rem-calc(0 0 15 0);
                    }
                    @include bp(tabletsm) {
                        font-size: rem-calc(24);
                    }
                }
                h3 {
                    margin: rem-calc(0 0 3 0);
                }
                .fifty-fifty-btn {
                    margin: rem-calc(41 0 0 0);
                    @include bp(tabletsm) {
                        margin: rem-calc(20 0 0 0);
                    }
                    a {
                        padding: rem-calc(9 31);
                    }
                }
            }
        }
    }
}