.service-generic-block {
    position: relative;
    border-bottom: 1px solid $gray;
    padding: rem-calc(50 0 16 0);
    @include bp(tabletXl) {
        padding: rem-calc(40 0 15);
    }
    @include bp(tabletsm) {
        padding: rem-calc(40 0 15);
    }
    &.service-generic-meetings-events {
        padding: rem-calc(50 0 70 0);
        @include bp(tabletXl) {
            padding: rem-calc(40 0 60);
        }
        @include bp(tabletsm) {
            padding: rem-calc(40 0 40);
        } 
    }
    .heading {
        margin-bottom: rem-calc(42);
        @include bp(tabletXl) {
            margin-bottom: rem-calc(20);
        }
        //margin-left: rem-calc(8);
        h2 {
            margin-bottom: rem-calc(5);
        }
        p {
            max-width: 860px;
            margin: 0 auto;
            width: 100%;
        }
    }
    .service-generic-list {
        margin: rem-calc(30 0 90 0);
        &:last-child(){
            margin-bottom: rem-calc(0);
        }
        &:nth-last-child(1){
            margin-bottom: rem-calc(0);
        }
        &:nth-last-child(2){
            margin-bottom: rem-calc(0);
        }
        &:nth-last-child(3){
            margin-bottom: rem-calc(0);
        }
        @include bp(tabletXl) {
            margin: rem-calc(0 0 40 0);
        }
        @include bp(tabletsm) {
            margin: rem-calc(0 0 30 0);
        }
        .service-generic-wrap {
            padding: rem-calc(0 0 0 0);
            .service-generic-bg-wrap {
                overflow: hidden;
                -webkit-transition:-webkit-transform 0.7s linear;
                transition:-webkit-transform 0.7s linear;
                transition:transform 0.7s linear;
                transition:transform 0.7s linear, -webkit-transform 0.7s linear;
                @include transition(0.5s, all, ease-in-out);
                &:hover {
                    .service-generic-bg {
                        -webkit-transform:scale(1.2);
                        -ms-transform:scale(1.2);
                        transform:scale(1.2);
                        -webkit-transition-duration:2s;
                        transition-duration:2s;
                    }
                }
                .service-generic-bg {
                    padding-bottom: 59.8%;
                    background-position: center center;
                    background-size: cover;
                    background-repeat: no-repeat;
                    position: relative;
                    cursor: pointer;
                    @include transition(0.4s, all, ease-in-out);
                }
            }
            h3 {
                margin: rem-calc(37 0 8 0);
                @include bp(tabletXl) {
                    margin: rem-calc(10 0 8 0);
                }
            }
            p {
                
            }
        }
    }
}