.blog-single-banner {
    position: relative;
    height: 462px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    @include bp(lg) {
        height: 400px;
    }
    @include bp(tabletmd) {
        height: 350px;
    }
    @include bp(tabletsm) {
        height: 300px;
    }
    &:before {
        content: "";
        background-color: rgba(10,10,51,0.4);
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
    }
    .blog-single-banner-cont {
        position: relative;
        z-index: 1;
        padding: rem-calc(125 0 90);
        margin: rem-calc(0 0 0 9);
        max-width: 1000px;
        width: 100%;
        @include bp(tabletXl) {
            padding: rem-calc(60 0 60);
        }
        @include bp(tabletsm) {
            padding: rem-calc(40 0 40);
        }
        p {
            color: $white;
            font-size: rem-calc(24);
            font-weight: $mediumdark;
            margin: rem-calc(0 0 19 0);
            padding: 0;
            @include bp(tabletsm) {
                font-size: rem-calc(20);
            }
        }
        h1 {
            color: $white;
            margin: rem-calc(0 0 10 0);
        }
        span {
            color: $white;
            font-size: 20px;
            font-weight: $normal;
        }
    }
}
.blog-single-cont-block {
    padding: rem-calc(80 0 80 0);
    @include bp(tabletXl) {
        padding: rem-calc(60 0 60);
    }
    @include bp(tabletsm) {
        padding: rem-calc(40 0 40);
    }
    .blog-single-cont {
        margin: rem-calc(0 109 0 15);
        @include bp(lg) {
            margin: rem-calc(0);
        }
        p {
            font-size: rem-calc(20);
            line-height: 1.5;
            color: $gray;
            margin: rem-calc(0 0 24 0);
            @include bp(lg) {
                font-size: rem-calc(18);
                margin-bottom: rem-calc(15);
            }
            @include bp(tabletXl) {
                font-size: rem-calc(16);
            }
            .alignnone {
                margin: 0px 5px 10px;
            }
        }
        h3 {
            margin: rem-calc(0 0 6 0);
        }
        .blog-gallery {
            .blog-item-gallery {
                padding-bottom: rem-calc(15);
            }
        }
    }
    .nav-links {
        margin: rem-calc(52 0 0 0);
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: rem-calc(0 9);
        @include bp(tabletXl) {
            margin: rem-calc(30 0 0 0);
        }
        .backto-page {
            @include bp(sm) {
                display: block;
                width: 100%;
                text-align: center;
                margin: rem-calc(0 0 15 0);
            }
            a {
                font-size: rem-calc(20);
                font-weight: $mediumdark;
                color: $gray;
                border: 1px solid $gray;
                padding: rem-calc(13 34);
                line-height: 1.2;
                display: inline-block;
                vertical-align: top;
                @include bp(tabletXl) {
                    font-size: rem-calc(18);
                    padding: rem-calc(11 25);
                }
                @include bp(tabletsm) {
                    font-size: rem-calc(16);
                }
                @include bp(sm) {
                    font-size: rem-calc(15);
                    padding: rem-calc(10 20);
                }
                &:hover {
                    background-color: $gray;
                    color: $white;
                    border-color: $gray;
                }
            }
        }
        .nav-next {
            @include bp(sm) {
                display: block;
                width: 100%;
                text-align: center;
            }
            a {
                font-size: rem-calc(20);
                font-weight: $mediumdark;
                color: $gray;
                border: 1px solid $gray;
                padding: rem-calc(13 52);
                line-height: 1.2;
                display: inline-block;
                vertical-align: top;
                @include bp(tabletXl) {
                    font-size: rem-calc(18);
                    padding: rem-calc(11 25);
                }
                @include bp(tabletsm) {
                    font-size: rem-calc(16);
                }
                @include bp(sm) {
                    font-size: rem-calc(15);
                    padding: rem-calc(10 20);
                }
                &:hover {
                    background-color: $gray;
                    color: $white;
                    border-color: $gray;
                }
            }  
        }
    }
}

.related-blog-posts {
    position: relative;
    padding: rem-calc(50 0 84 0);
    @include bp(tabletXl) {
        padding: rem-calc(50 0 50);
    }
    @include bp(tabletsm) {
        padding: rem-calc(40 0 40);
    }
    .heading {
        margin: rem-calc(0 0 60 0);
        @include bp(tabletXl) {
            margin: 0 0 30px 0;
        }
    }
    .related-blog-wrap {
        margin: 0 0 0 5px;
        @include bp(tabletXl) {
            margin: 0 0 30px 0;
        }
        a {
            display: inline-block;
            vertical-align: top;
            width: 100%;
            &:hover {
                .related-blog-cont {
                    span {
                        color: $red;
                    }
                    h3 {
                        color: $red;
                    }
                }
            }
            .related-blog-bg-wrap {
                width: 100%;
                height: 100%;
                overflow: hidden;
                -webkit-transition:-webkit-transform 0.7s linear;
                transition:-webkit-transform 0.7s linear;
                transition:transform 0.7s linear;
                transition:transform 0.7s linear, -webkit-transform 0.7s linear;
                @include transition(0.5s, all, ease-in-out);
                &:hover {
                    .related-blog-bg {
                        -webkit-transform:scale(1.2);
                        -ms-transform:scale(1.2);
                        transform:scale(1.2);
                        -webkit-transition-duration:2s;
                        transition-duration:2s;
                    }
                }
                .related-blog-bg {
                    padding-bottom: 60.25%;
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    position: relative;
                    @include transition(0.5s, all, ease-in-out);
                }
            }
            .related-blog-cont {
                margin: rem-calc(24 40 0 4);
                @include bp(tabletXl) {
                    margin: rem-calc(15 0 0 0);
                }
                span {
                    display: inline-block;
                    vertical-align: top;
                    font-size: rem-calc(20);
                    font-weight: $normal;
                    line-height: 1.2;
                    color: $gray;
                    margin: rem-calc(0 0 7 0);
                    @include bp(tabletXl) {
                        font-size: rem-calc(18);
                    }
                    @include bp(tabletsm) {
                        font-size: rem-calc(16);
                    }
                }
                h3 {
                    font-size: rem-calc(24);
                    line-height: 1.3;
                    font-weight: $mediumdark;
                    color: $gray;
                    @include bp(tabletXl) {
                        font-size: rem-calc(20);
                    }
                }
            }
        }
    }
}