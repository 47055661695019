footer {
    width: 100%;
    padding: rem-calc(48 0);
    @include bp(tabletXl) {
        padding: rem-calc(40 0);
    }
    @include bp(tabletsm) {
        padding: rem-calc(30 0);
    }
    .footer-wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        margin: 0 0 22px 0;
        @include bp(tabletXl) {
            margin: 0 0 10px 0;
        }
        @include bp(tabletsm) {
            margin: 0 0 15px 0;
        }
        .footer-menu {
            width: calc(100% - 75px);
            margin: 10px 0 0 0;
            @include bp(tabletmd) {
                width: 100%;
            }
            ul {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                li {
                    list-style-type: none;
                    margin: 0 20px 0 0;
                    @include bp(sm) {
                        width: 100%;
                        margin: 0 0 5px 0;
                        text-align: center
                    }
                    &:last-child {
                        margin: 0;
                    }
                    a {
                        font-size: rem-calc(16);
                        font-weight: $mediumdark;
                        color: $dark-black;
                        &:hover {
                            color: $red;
                        }
                    }
                }
            }
        }
        .footer-social {
            @include bp(sm) {
                margin: 15px 0 0 0;
            }
            ul {
                display: flex;
                flex-wrap: wrap;
                li {
                    list-style-type: none;
                    margin: 0 25px 0 0;
                    &:last-child {
                        margin: 0;
                    }
                    a {
                        font-size: 27px;
                        line-height: normal;
                        color: $red;
                        display: inline-block;
                        vertical-align: middle;
                        &:hover {
                            color: $dark-black;
                        }
                    }
                }
            }
        }        
    }
    .copyright-text {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        p {
            font-size: rem-calc(13);
            line-height: normal;
            color: $dark-black;
            margin: 0;
        }
        ul {
            display: flex;
            flex-wrap: wrap;
            li {
                list-style-type: none;
                border-left: 1px solid $dark-black;
                line-height: 0;
                padding: 0 6px;
                margin: rem-calc(0 0 0 10);
                &:last-child {
                    margin: rem-calc(0 10 0 0);
                }
                a {
                    font-size: rem-calc(13);
                    line-height: rem-calc(13);
                    color: $dark-black;
                    &:hover {
                        color: $red;
                    }
                }
            }
        }
    }
}