@charset 'utf-8';



@import 'bootstrap';
@import 'settings';
@import 'motion-ui';



/*
  My custom styles:
  Add your scss files below to structure your project styles
*/



// Global
@import "global/accessibility";
@import "global/colors";
@import "global/wp-admin";
@import "global/mixing";
@import "global/variable";
@import "global/header";
@import "global/general";

// Modules
@import "modules/navigation"; 
@import "modules/footer";
@import "modules/font-awesome";
@import "modules/editor-style";
@import "modules/menu";
@import "modules/animate";
@import "modules/magnific-popup";
@import "modules/slick";
@import "modules/slick-theme";
@import "modules/datepicker";
@import "modules/lightgallery";

//content-modules
@import "content-modules/home-hero-banner";
@import "content-modules/inner-hero-banner";
@import "content-modules/plan-your-event";
@import "content-modules/call-to-action";
@import "content-modules/post-cards";
@import "content-modules/projects-carousel";
@import "content-modules/destinations-carousel";
@import "content-modules/brands-logo";
@import "content-modules/featured-resources";
@import "content-modules/footer-cta";
@import "content-modules/fifty-fifty-block";
@import "content-modules/service-generic-block";
@import "content-modules/generic-content-block";
@import "content-modules/cards-with-icons";
@import "content-modules/event-type-block";
@import "content-modules/subpages-cards";
@import "content-modules/destinations-single";
@import "content-modules/destination-gallery";
@import "content-modules/project-gallery";
@import "content-modules/project-gallery-single";
@import "content-modules/blog-list-block";
@import "content-modules/blog-single";
@import "content-modules/about-us";
@import "content-modules/meet-the-team";
@import "content-modules/plan-your-event-block";
@import "content-modules/contact-us-block";
@import "content-modules/accordion-block";
@import "content-modules/full-width-image-block";




