.plan-your-event-block {
    position: relative;
    padding: rem-calc(86 0 126);
    @include bp(tabletXl) {
        padding: rem-calc(40 0);
    }
    @include bp(tabletsm) {
        padding: rem-calc(30 0);
    }
    .plan-your-event-block-cont {
        margin: 0 0 0 8px;
        @include bp(tabletXl) {
            margin: 0 0 0 0px;
        }
        span {
            font-size: rem-calc(24);
            font-weight: $mediumdark;
            color: $gray;
            display: inline-block;
            vertical-align: top;
            margin: 0 0 29px 0
        }
        h1 {
            margin: 0 0 11px 0;
        }
        p {
            font-size: 20px;
            @include bp(lg) {
                font-size: rem-calc(18);
                line-height: 1.5;
            }
            @include bp(tabletXl) {
                font-size: rem-calc(16);
            }
        }
        .plan-your-event-form {
            margin: 65px 0 0 0;
            .gform_confirmation_message {
                font-size: 22px;
                font-weight: 600;
                text-align: center;
                line-height: normal;
            }
            .gform_wrapper {
                background-color: transparent;
                form {
                    .validation_error {
                        color: $red;
                        font-size: 16px;
                        margin: 0 0 10px 0;
                        line-height: normal;
                    }
                    .gform_body {
                        ul {
                            display: flex;
                            flex-wrap: wrap;
                            margin: 0 -15px;
                            @include bp(sm) {
                                margin: 0 0;
                            }
                            li {
                                list-style-type: none;
                                width: 50%;
                                padding: 0 15px 15px;
                                @include bp(tabletsm) {
                                    padding: 0 10px 10px;
                                }
                                @include bp(sm) {
                                    width: 100%;
                                    padding: rem-calc(0 0 15 0);
                                }
                                .ginput_container_checkbox {
                                    ul {
                                        &.gfield_checkbox {
                                            li {
                                                width: 33.33%;
                                                padding: 0 0 0 15px;
                                                @include bp(tabletmd) {
                                                    width: 50%;
                                                }
                                                @include bp(sm) {
                                                    width: 100%;
                                                    padding: 0;
                                                }
                                            }
                                        }
                                    }
                                }
                                &.full-width {
                                    width: 100%;
                                    text-align: center;
                                    font-size: rem-calc(28);
                                    font-weight: $mediumdark;
                                    line-height: normal;
                                    color: $red;
                                    margin: 130px 0 9px;
                                    @include bp(tabletXl) {
                                        margin: 60px 0 0px;
                                    }
                                    @include bp(sm) {
                                        margin: 40px 0 0px;
                                    }
                                }
                                &.theater-icon {
                                    width: 100%;
                                    position: relative;
                                    label {
                                        &.gfield_label {
                                            display: none;
                                        }
                                    }
                                    ul {
                                        margin: 0 -5px;
                                        li {
                                            padding: 0px 0 0 17px;
                                            width: 14%;
                                            text-align: center;
                                            @include bp(tabletmd) {
                                                width: 20%;
                                            }
                                            @include bp(tabletsm) {
                                                width: 25%;
                                                padding: 0 5px 0 5px;
                                            }
                                            @include bp(sm) {
                                                width: 33.33%;
                                            }
                                            @include bp(xsm) {
                                                width: 50%;
                                            }
                                            
                                            &.gchoice_11_14_0 {
                                                label {
                                                    display: inline-block;
                                                    vertical-align: top;
                                                    font-size: 20px;
                                                    font-weight: 400; 
                                                    width: 100%;
                                                    height: 100%;
                                                    padding: 130px 0 20px 0;
                                                    text-align: center;
                                                    position: relative;
                                                    z-index: 1;
                                                    @include bp(tabletsm) {
                                                        padding: 110px 0 20px 0;
                                                    }
                                                    &:before {
                                                        content: "";
                                                        background-image: url(../images/theater.png);
                                                        background-repeat: no-repeat;
                                                        background-size: 68px 54px;
                                                        width: 100%;
                                                        height: 100%;
                                                        border-radius: 0;
                                                        border: 0;
                                                        background-position: top center;
                                                        background-color: transparent;
                                                        margin: 42px 0 0;
                                                    }
                                                } 
                                            }
                                            &.gchoice_11_14_1 {
                                                label {
                                                    display: inline-block;
                                                    vertical-align: top;
                                                    font-size: 20px;
                                                    font-weight: 400; 
                                                    width: 100%;
                                                    height: 100%;
                                                    padding: 130px 0 20px 0;
                                                    text-align: center;
                                                    position: relative;
                                                    z-index: 1;
                                                    @include bp(tabletsm) {
                                                        padding: 110px 0 20px 0;
                                                    }
                                                    &:before {
                                                        content: "";
                                                        background-image: url(../images/class.png);
                                                        background-repeat: no-repeat;
                                                        background-size: 43px 55px;
                                                        width: 100%;
                                                        height: 100%;
                                                        border-radius: 0;
                                                        border: 0;
                                                        background-position: top center;
                                                        background-color: transparent;
                                                        margin: 42px 0 0;
                                                    }
                                                } 
                                            }
                                            &.gchoice_11_14_2 {
                                                label {
                                                    display: inline-block;
                                                    vertical-align: top;
                                                    font-size: 20px;
                                                    font-weight: 400; 
                                                    width: 100%;
                                                    height: 100%;
                                                    padding: 130px 0 20px 0;
                                                    text-align: center;
                                                    position: relative;
                                                    z-index: 1;
                                                    @include bp(tabletsm) {
                                                        padding: 110px 0 20px 0;
                                                    }
                                                    &:before {
                                                        content: "";
                                                        background-image: url(../images/cocktail.png);
                                                        background-repeat: no-repeat;
                                                        background-size: 48px 54px;
                                                        width: 100%;
                                                        height: 100%;
                                                        border-radius: 0;
                                                        border: 0;
                                                        background-position: top center;
                                                        background-color: transparent;
                                                        margin: 42px 0 0;
                                                    }
                                                } 
                                            }
                                            &.gchoice_11_14_3 {
                                                label {
                                                    display: inline-block;
                                                    vertical-align: top;
                                                    font-size: 20px;
                                                    font-weight: 400; 
                                                    width: 100%;
                                                    height: 100%;
                                                    padding: 130px 0 20px 0;
                                                    text-align: center;
                                                    position: relative;
                                                    z-index: 1;
                                                    @include bp(tabletsm) {
                                                        padding: 110px 0 20px 0;
                                                    }
                                                    &:before {
                                                        content: "";
                                                        background-image: url(../images/meeting.png);
                                                        background-repeat: no-repeat;
                                                        background-size: 58px 54px;
                                                        width: 100%;
                                                        height: 100%;
                                                        border-radius: 0;
                                                        border: 0;
                                                        background-position: top center;
                                                        background-color: transparent;
                                                        margin: 42px 0 0;
                                                    }
                                                } 
                                            }
                                            &.gchoice_11_14_4 {
                                                label {
                                                    display: inline-block;
                                                    vertical-align: top;
                                                    font-size: 20px;
                                                    font-weight: 400; 
                                                    width: 100%;
                                                    height: 100%;
                                                    padding: 130px 0 20px 0;
                                                    text-align: center;
                                                    position: relative;
                                                    z-index: 1;
                                                    @include bp(tabletsm) {
                                                        padding: 110px 0 20px 0;
                                                    }
                                                    &:before {
                                                        content: "";
                                                        background-image: url(../images/u.png);
                                                        background-repeat: no-repeat;
                                                        background-size: 58px 54px;
                                                        width: 100%;
                                                        height: 100%;
                                                        border-radius: 0;
                                                        border: 0;
                                                        background-position: top center;
                                                        background-color: transparent;
                                                        margin: 42px 0 0;
                                                    }
                                                } 
                                            }
                                            &.gchoice_11_14_5 {
                                                label {
                                                    display: inline-block;
                                                    vertical-align: top;
                                                    font-size: 20px;
                                                    font-weight: 400; 
                                                    width: 100%;
                                                    height: 100%;
                                                    padding: 130px 0 20px 0;
                                                    text-align: center;
                                                    position: relative;
                                                    z-index: 1;
                                                    @include bp(tabletsm) {
                                                        padding: 110px 0 20px 0;
                                                    }
                                                    &:before {
                                                        content: "";
                                                        background-image: url(../images/banquet.png);
                                                        background-repeat: no-repeat;
                                                        background-size: 58px 54px;
                                                        width: 100%;
                                                        height: 100%;
                                                        border-radius: 0;
                                                        border: 0;
                                                        background-position: top center;
                                                        background-color: transparent;
                                                        margin: 42px 0 0;
                                                    }
                                                } 
                                            }
                                            &.gchoice_11_14_6 {
                                                label {
                                                    display: inline-block;
                                                    vertical-align: top;
                                                    font-size: 20px;
                                                    font-weight: 400; 
                                                    width: 100%;
                                                    height: 100%;
                                                    padding: 130px 0 20px 0;
                                                    text-align: center;
                                                    position: relative;
                                                    z-index: 1;
                                                    @include bp(tabletsm) {
                                                        padding: 110px 0 20px 0;
                                                    }
                                                    &:before {
                                                        content: "";
                                                        background-image: url(../images/cabaret.png);
                                                        background-repeat: no-repeat;
                                                        background-size: 68px 54px;
                                                        width: 100%;
                                                        height: 100%;
                                                        border-radius: 0;
                                                        border: 0;
                                                        background-position: top center;
                                                        background-color: transparent;
                                                        margin: 42px 0 0;
                                                    }
                                                } 
                                            }
                                        }
                                    }
                                }
                                
                                &:focus {
                                    outline: none;
                                }
                                > label {
                                    &.gfield_label {
                                        display: none;
                                    }
                                }
                                .ginput_container_date {
                                    input {
                                        background-image: url(../images/select-icon.svg);
                                        background-position: 97% center;
                                        background-repeat: no-repeat;
                                        background-size: 14px 12px;
                                    }
                                }
                                select {
                                    width: 100%!important;
                                    border: 1px solid #545558;
                                    //color: $gray;
                                    padding: 10px 10px!important;
                                    background-image: url(../images/select-icon.svg);
                                    background-position: 97% center;
                                    background-repeat: no-repeat;
                                    background-size: 14px 12px;
                                    border-radius: 0;
                                    box-shadow: none;
                                    font-size: rem-calc(18);
                                    line-height: normal;
                                    font-weight: $normal;
                                    -webkit-appearance: none;
                                    min-width: inherit;
                                    option {
                                        &:first-child {
                                            color:#c6c5c5; 
                                        }
                                        color: $gray;
                                    }
                                    
                                    @include bp(tabletsm) {
                                        font-size: rem-calc(16);
                                    }
                                    &:focus {
                                        outline: none;
                                    }
                                }
                                input {
                                    width: 100%!important;
                                    border: 1px solid #545558;
                                    color: $gray;
                                    padding: 10px 10px!important;
                                    border-radius: 0;
                                    box-shadow: none;
                                    font-size: rem-calc(18);
                                    line-height: normal;
                                    font-weight: $normal;
                                    -webkit-appearance: none;
                                    min-width: inherit;
                                    @include bp(tabletsm) {
                                        font-size: rem-calc(16);
                                    }
                                    &:focus {
                                        outline: none;
                                    }
                                }
                                textarea {
                                    resize: none;
                                    height: 143px;
                                    border-radius: 0;
                                    box-shadow: none;
                                    font-size: rem-calc(18);
                                    line-height: normal;
                                    font-weight: $normal;
                                    color: $gray;
                                    border: 1px solid #545558;
                                    min-width: inherit;
                                    @include bp(tabletsm) {
                                        font-size: rem-calc(16);
                                    }
                                    &:focus {
                                        outline: none;
                                    }
                                }
                                .validation_message {
                                    color: $red;
                                    font-size: rem-calc(14);
                                    line-height: normal;
                                    margin: rem-calc(5 0 0 0);
                                }
                            }
                        }
                    }
                    .gform_footer {
                        margin: 53px 0 0 0;
                        text-align: center;
                        @include bp(tabletsm) {
                            margin: 35px 0 0 0;
                        }
                        input[type='submit'] {
                            color: $white;
                            background-color: $red;
                            padding: rem-calc(10 55);
                            font-size: rem-calc(20);
                            opacity: 1;
                            font-weight: $mediumdark;
                            border: 1px solid transparent;
                            &:hover {
                                background-color: transparent;
                                border-color: $red;
                                color: $red;
                            }
                            &:focus {
                                background-color: transparent;
                                border-color: $red;
                                color: $red;
                                outline: none;
                            }
                        }
                    }
                }
            }
        }
    }
}