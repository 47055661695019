.project-gallery-single-banner {
    position: relative;
    padding: rem-calc(60 0);
    @include bp(tabletXl) {
        padding: rem-calc(40 0 40);
    }
    @include bp(tabletsm) {
        padding: rem-calc(30 0 30);
    }
    .project-gallery-single-banner-cont {
        margin: 0 0 0 13px;
        max-width: 800px;
        width: 100%;
        span {
            font-size: rem-calc(24);
            color: $gray;
            font-weight: $mediumdark;
            margin: 0 0 18px 0;
            display: inline-block;
            vertical-align: top;
            @include bp(tabletXl) {
                font-size: rem-calc(20);
                margin: 0 0 12px 0;
            }
            @include bp(tabletsm) {
                font-size: rem-calc(18);
            }
        }
        h1 {
            margin: 0 0 10px 0;
        }
        ul {
            display: flex;
            flex-wrap: wrap;
            margin: 0 0 22px 0;
            li {
                font-weight: $mediumdark;
                font-size: rem-calc(28);
                color: $red;
                border-right: 4px solid $red;
                padding: 0 12px 0 0;
                margin: 0 15px 0 0;
                list-style-type: none;
                @include bp(lg) {
                    font-size: rem-calc(24);
                }
                @include bp(tabletXl) {
                    font-size: rem-calc(20);
                    line-height: 22px;
                    border-right: 2px solid $red;
                }
                @include bp(tabletsm) {
                    font-size: rem-calc(18);
                }
                &:last-child {
                    border: 0;
                    margin: 0;
                    padding: 0;
                }
            }
        }
        p {
            font-size: rem-calc(20);
            line-height: 1.5;
            @include bp(lg) {
                font-size: rem-calc(18);
                margin-bottom: rem-calc(15);
                line-height: 1.5;
            }
            @include bp(tabletXl) {
                font-size: rem-calc(16);
            }
        }
    }
}
.project-gallery-single {
    padding: rem-calc(27 0 134 0);
    @include bp(tabletXl) {
        padding: rem-calc(30 0 40);
    }
    @include bp(tabletsm) {
        padding: rem-calc(30 0 50);
    }
    .project-gallery-single-wrap {
        margin: rem-calc(0 105);
        display: flex;
        flex-wrap: wrap;
        @include bp(tabletmd) {
            margin: rem-calc(0 30);
        }
        @include bp(tabletsm) {
            margin: rem-calc(0 0);
        }
        .project-gallery-single-list {
            &.full-bg {
                width: 100%;
                margin: 0 0 23px 0;
                @include bp(tabletsm) {
                    margin: 0 0 15px 0;
                }
                .project-gallery-bg {
                    padding-bottom: 57.20%;
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    position: relative;
                }
            }
            &.two-col-55 {
                width: 55.6%;
                @include bp(xsm) {
                    width: 100%;
                }
                .project-gallery-bg {
                    padding-bottom: 67.5%;
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    position: relative;
                }
            }
            &.two-col-45 {
                width: 44.4%;
                padding: 0 0 0 22px;
                @include bp(xsm) {
                    width: 100%;
                    padding: 15px 0 0 0;
                }
                .project-gallery-bg {
                    padding-bottom: 88.5%;
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    position: relative;
                    height: 100%;
                }
            }
        }
    }
    .nav-links {
        margin: 56px 0 0 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0px 8px;
        @include bp(tabletsm) {
            margin: 25px 0 0 0;
        }
        .nav-previous {
            a {
                font-size: 20px;
                font-weight: $mediumdark;
                color: $red;
                @include bp(tabletsm) {
                    font-size: 16px;
                }
                &:hover {
                    color: $gray;
                }
            }
        }
        .nav-next {
            a {
                font-size: 20px;
                font-weight: $mediumdark;
                color: $red;
                @include bp(tabletsm) {
                    font-size: 16px;
                }
                &:hover {
                    color: $gray;
                }
            }
        }
    }
}