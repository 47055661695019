.destinations-carousel {
    position: relative;
    padding: rem-calc(55 0);
    @include bp(tabletXl) {
        padding: rem-calc(40 0);
    }
    @include bp(tabletsm) {
        padding: rem-calc(30 0);
    }
    /*.container {
        background-color: #f6f6f7;    
    }*/
    .heading {
        width: 100%;
        padding: rem-calc(83 0 65 0);
        margin: rem-calc(0 78 0 0);
        @include bp(lg) {
            margin: rem-calc(0 25 0 0);
            padding: rem-calc(60 0 60 0);
        }
        @include bp(tabletmd) {
            margin: rem-calc(0 0 0 0);
            padding: rem-calc(60 0 60 0);
        }
        @include bp(tabletmd) {
            padding: rem-calc(40 0 40 0);
        }
        h2 {
            margin: rem-calc(0 0 3 0);
        }
        p {
            font-size: rem-calc(20);
            color: $gray;
            @include bp(tabletXl) {
                font-size: rem-calc(16);
                line-height: 1.45;
            }
            @include bp(tabletsm) {
                font-size: rem-calc(14);
            }
        }
    }
    .destinations-carousel-wrap {
        max-width: 100%;
        margin: 0 0 0 auto;
        width: 100%;
        &:focus {
            outline: none;
        }
        .destinations-carousel-list {
            display: flex;
            flex-wrap: wrap;
            padding: rem-calc(0 54 54 131);
            @include bp(lg) {
                padding: rem-calc(0 50 50 50);
            }
            @include bp(tabletsm) {
                padding: rem-calc(0 40 40);
            }
            &:focus {
                outline: none;
            }
            .destination-inner {
                display: flex;
                flex-wrap: wrap;
                &.color-gray {
                    .destinations-slider-cont-wrap {
                        .destinations-slider-cont {
                            h3 {
                                color: $red;
                            }
                        }
                    }
                }
                .destinations-carousel-lt {
                    width: 62.3%;
                    padding: 0 18px 0 0;
                    @include bp(tabletsm) {
                        padding: 0 10px 0 0;
                    }
                    @include bp(xsm) {
                        padding: 0 0 15px 0;
                        width: 100%;
                    }
                    &:focus {
                        outline: none;
                    }
                    .destinations-carousel-bg-wrap {
                        height: 100%;
                        overflow: hidden;
                        -webkit-transition:-webkit-transform 0.7s linear;
                        transition:-webkit-transform 0.7s linear;
                        transition:transform 0.7s linear;
                        transition:transform 0.7s linear, -webkit-transform 0.7s linear;
                        @include transition(0.5s, all, ease-in-out);
                        &:hover {
                            .destinations-slider-bg {
                                -webkit-transform:scale(1.2);
                                -ms-transform:scale(1.2);
                                transform:scale(1.2);
                                -webkit-transition-duration:2s;
                                transition-duration:2s;
                            }
                        }
                        .destinations-slider-bg {
                            padding-bottom: 56.25%;
                            background-position: center center;
                            background-repeat: no-repeat;
                            background-size: cover;
                            height: 100%;
                            position: relative;
                            @include transition(0.5s, all, ease-in-out);
                        }
                    }
                }
                .destinations-carousel-rt {
                    width: 32.7%;
                    padding: 0 25px 0 16px;
                    @include bp(lg) {
                        width: 37.7%;
                        padding: 0 0 0 16px;
                    }
                    @include bp(tabletsm) {
                        padding: 0 0 0 10px;
                    }
                    @include bp(xsm) {
                        padding: 0 0 0 0;
                        width: 100%;
                    }
                    &:focus {
                        outline: none;
                    }
                    .destinations-rt-bg-list {
                        margin: rem-calc(0 0 30 0);
                        @include bp(xsm) {
                            margin: rem-calc(0 0 15 0);
                        }
                        &:last-child {
                            margin: 0;
                        }
                        .destinations-carousel-bg-wrap {
                            height: 100%;
                            overflow: hidden;
                            -webkit-transition:-webkit-transform 0.7s linear;
                            transition:-webkit-transform 0.7s linear;
                            transition:transform 0.7s linear;
                            transition:transform 0.7s linear, -webkit-transform 0.7s linear;
                            @include transition(0.5s, all, ease-in-out);
                            &:hover {
                                .destinations-slider-bg {
                                    -webkit-transform:scale(1.2);
                                    -ms-transform:scale(1.2);
                                    transform:scale(1.2);
                                    -webkit-transition-duration:2s;
                                    transition-duration:2s;
                                }
                            }
                            .destinations-slider-bg {
                                padding-bottom: 73.25%;
                                background-position: center center;
                                background-repeat: no-repeat;
                                background-size: cover;
                                height: 100%;
                                position: relative;
                                @include transition(0.5s, all, ease-in-out);
                            }
                        }
                    }
                }
                .destinations-slider-cont-wrap {
                    margin: rem-calc(32 0 0 0);
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    align-items: flex-end;
                    .destinations-slider-cont {
                        width: 58%;
                        @include bp(xsm) {
                            width: 100%;
                            padding: rem-calc(0 0 15 0);
                        }
                        h3 {
                            font-size: rem-calc(42);
                            line-height: normal;
                            font-weight: $medium;
                            margin: rem-calc(0 0 0 0);
                            @include bp(tabletXl) {
                                font-size: rem-calc(28);
                                line-height: 1.2;
                            }
                            @include bp(tabletsm) {
                                font-size: rem-calc(24);
                            }
                        }
                    }
                    .destinations-slider-btn {
                        a {
                            @include bp(tabletXl) {
                                font-size: rem-calc(16);
                                line-height: 1.2;
                            }
                            @include bp(tabletsm) {
                                padding: rem-calc(10 11);
                                font-size: rem-calc(14);
                            }
                        }
                    }
                }   
            }
        }
        .slick-prev {
            left: 65px;
            z-index: 1;
            width: 17px;
            height: 21px;
            top: 50%;
            transform: translateY(-50%);
            margin: 0 auto;
            @include bp(lg) {
                left: 15px;
            }
            @include bp(tabletsm) {
                left: 9px;
            }
            &:before {
                content: "";
                background-image: url(../images/desti-slider-left.svg);
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;
                width: rem-calc(14);
                height: rem-calc(21);
                position: absolute;
                left: 0px;
                top: 0;
                z-index: 1;
                opacity: 1;
            }
        }
        .slick-next {
            right: 70px;
            z-index: 1;
            width: 14px;
            height: 21px;
            top: 50%;
            transform: translateY(-50%);
            margin: 0 auto;
            @include bp(lg) {
                right: 15px;
            }
            @include bp(tabletsm) {
                right: 9px;
            }
            &:before {
                content: "";
                background-image: url(../images/desti-slider-right.svg);
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;
                width: rem-calc(14);
                height: rem-calc(21);
                position: absolute;
                left: 0px;
                top: 0;
                z-index: 1;
                opacity: 1;
            }
        }
    }
}