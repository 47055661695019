.generic-content-block {
    position: relative;
    border-bottom: 1px solid #54565a;
    padding: 0 0 70px;
    @include bp(tabletXl) {
        padding: rem-calc(0 0 40);
    }
    @include bp(tabletsm) {
        padding: rem-calc(0 0 30);
    }
    .generic-content-slider {
        .slick-list {
            max-width: 1240px;
            width: 100%;
            margin: 0 auto;
            .generic-slider-list {
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;
                height: 290px;
                margin: 31px 0 0 0;
                @include bp(tabletsm) {
                    height: 250px;
                }
                &:before {
                    content: "";
                    background-color: rgba(255,255,255,0.5);
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                }
                &.generic-video {
                    a {
                        position: relative;
                        &:before {
                            content: "";
                            background-image: url(../images/video-icon.svg);
                            background-position: center center;
                            background-size: cover;
                            background-repeat: no-repeat;
                            left: 0;
                            top: 50%;
                            width: 103%;
                            height: 123%;
                            position: absolute;
                            z-index: 2;
                            transform: translateY(-50%);
                        }
                    }  
                }
                &.slick-slide {
                    display: inline-block; 
                    vertical-align: middle; 
                    float: none;
                    transform: scale(1);
                    transition: transform .3s;
                    &.slick-current {
                        transform: scale(1.7);
                        position: relative;
                        z-index: 1;
                        z-index: 2;
                        height: 380px;
                        background-size: contain;
                        &:before {
                            display: none;
                        }
                        @include bp(tabletmd) {
                            height: 170px;
                        }
                        @include bp(tabletsm) {
                            height: 250px;
                            transform: scale(1);
                        }
                    }
                }
                a {
                    position: relative;
                    display: inline-block;
                    vertical-align: top;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
    .generic-slider-cont-wrap {
        max-width: 680px;
        margin: 40px auto 0;
        width: 100%;
        text-align: center;
        padding: 0 0 157px 0;
        &.generic-slider-one {
            padding: 0 0 0px 0;
            .generic-slider-cont {
                margin: 0;
            }
        }
        @include bp(tabletmd) {
            margin: 0 auto;
            padding: 20px 0 100px 0;
        }
        ul {
            &.slick-dots {
                bottom: -45px;
                li {
                    width: auto;
                    height: auto;
                    &:only-child {
                        display: none;
                    }
                    &.slick-active {
                        button {
                            opacity: 1;
                        }
                    }
                    button {
                        -webkit-appearance: none;
                        width: 13px;
                        height: 13px;
                        display: inline-block;
                        vertical-align: middle;
                        background-color: #40405c;
                        border-radius: 50%;
                        opacity: 0.5;
                        line-height: normal;
                        opacity: 0.5;
                    }
                }
            }
        }
        
        .slick-prev {
            left: -65px;
            z-index: 1;
            width: 45px;
            height: 45px;
            bottom: -122px;
            top: inherit;
            transform: translateY(0);
            right: 0;
            margin: 0 auto;
            @include bp(tabletmd) {
                bottom: -110px;
            }
            &:before {
                content: "";
                background-image: url(../images/generic-slider-left-icon.svg);
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;
                width: rem-calc(45);
                height: rem-calc(45);
                position: absolute;
                left: 0px;
                top: 0;
                z-index: 1;
                opacity: 1;
            }
        }
        .slick-next {
            right: -75px;
            z-index: 1;
            width: 45px;
            height: 45px;
            bottom: -122px;
            top: inherit;
            transform: translateY(0);
            left: 0;
            margin: 0 auto;
            @include bp(tabletmd) {
                bottom: -110px;
            }
            &:before {
                content: "";
                background-image: url(../images/generic-slider-right-icon.svg);
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;
                width: rem-calc(45);
                height: rem-calc(45);
                position: absolute;
                left: 0px;
                top: 0;
                z-index: 1;
                opacity: 1;
            }
        }
    }
}