.about-us-banner {
    position: relative;
    height: 462px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    @include bp(lg) {
        height: 400px;
    }
    @include bp(tabletXl) {
        height: 340px;
    }
    &:before {
        content: "";
        background-color: rgba(10,10,51,0.4);
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
    }
    .about-us-banner-cont {
        position: relative;
        z-index: 1;
        padding: rem-calc(134 0);
        margin: rem-calc(0 0 0 0);
        max-width: 1000px;
        width: 100%;
        @include bp(lg) {
            padding: rem-calc(80 0 80);
        }
        p {
            color: $white;
            font-size: rem-calc(24);
            font-weight: $mediumdark;
            margin: rem-calc(0 0 3 0);
            padding: 0;
            @include bp(tabletXl) {
                font-size: rem-calc(20);
            }
        }
        h1 {
            color: $white;
            margin: rem-calc(0 0 10 0);
        }
    }
}
.about-us-content {
    padding: rem-calc(95 0 100);
    @include bp(tabletXl) {
        padding: rem-calc(40 0);
    }
    @include bp(tabletsm) {
        padding: rem-calc(30 0);
    }
    .about-us-cont {
        margin: rem-calc(0 0 0 0);
        @include bp(tabletmd) {
            margin: rem-calc(0 0 0 0);
        }
        p {
            font-size: rem-calc(20);
            line-height: 1.5;
            color: $gray;
            margin: rem-calc(0 0 18 0);
            @include bp(tabletXl) {
                font-size: rem-calc(18);
            }
            @include bp(tabletsm) {
                font-size: rem-calc(16);
            }
        }
    }
}