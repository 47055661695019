.blog-list-block {
    position: relative;
    padding: 10px 0 48px 0;
    @include bp(tabletXl) {
        padding: rem-calc(10 0 40);
    }
    @include bp(tabletsm) {
        padding: rem-calc(10 0 30);
    }
    .blog-list {
        margin: rem-calc(0 0 65 0);
        @include bp(tabletXl) {
            margin: rem-calc(0 0 30 0);
        }
        .blog-list-wrap {
            margin: 0 0 0 5px;
            a {
                display: inline-block;
                vertical-align: top;
                width: 100%;
                &:hover {
                    .blog-cont {
                        span {
                            color: $red;
                        }
                        h2 {
                            color: $red;
                        }
                    }
                }
                .blog-bg-wrap {
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                    -webkit-transition:-webkit-transform 0.7s linear;
                    transition:-webkit-transform 0.7s linear;
                    transition:transform 0.7s linear;
                    transition:transform 0.7s linear, -webkit-transform 0.7s linear;
                    @include transition(0.5s, all, ease-in-out);
                    &:hover {
                        .blog-bg {
                            -webkit-transform:scale(1.2);
                            -ms-transform:scale(1.2);
                            transform:scale(1.2);
                            -webkit-transition-duration:2s;
                            transition-duration:2s;
                        }
                    }
                    .blog-bg {
                        padding-bottom: 60.25%;
                        background-position: center center;
                        background-repeat: no-repeat;
                        background-size: cover;
                        position: relative;
                        @include transition(0.5s, all, ease-in-out);
                    }
                }
                .blog-cont {
                    margin: rem-calc(24 40 0 4);
                    span {
                        display: inline-block;
                        vertical-align: top;
                        font-size: rem-calc(20);
                        font-weight: $normal;
                        line-height: 1.2;
                        color: $gray;
                        margin: rem-calc(0 0 9 0);
                        @include bp(tabletXl) {
                            font-size: rem-calc(18);
                        }
                    }
                    h2 {
                        font-size: rem-calc(24);
                        line-height: 1.3;
                        font-weight: $mediumdark;
                        color: $gray;
                        @include bp(tabletXl) {
                            font-size: rem-calc(20);
                        }
                    }
                }
            }
        }
    }
}