/*
Only required is to display none the .menu-icon
*/
.menu-icon {
    display: none;
    cursor: pointer;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
    line-height: 33px;
    font-size: 22px;
    padding: 10px 0;

    .menu-box {
        width: 40px;
        height: auto;
        margin-right: 0;
        padding: 0 5px;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        span {
            width: 100%;
            height: 3px;
            margin-bottom: 5px;
            background: $red;
            display: block;
            border-radius: 0px;
            transition: all 0.5s;
            -webkit-transition: all 0.5s;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &.active span:nth-child(1) {
        transform: rotate(45deg) translateY(8px) translateX(6px);
        -webkit-transform: rotate(45deg) translateY(8px) translateX(6px);
    }

    &.hover:not(.active) span:nth-child(1) {
        transform: rotate(90deg) translateX(8px);
        -webkit-transform: rotate(90deg) translateX(8px);
    }

    &.active span {
        &:nth-child(2) {
            opacity: 0;
        }

        &:nth-child(3) {
            transform: rotate(-45deg) translateY(-5px) translateX(3px);
            -webkit-transform: rotate(-45deg) translateY(-5px) translateX(3px);
        }
    }

    &.hover:not(.active) span:nth-child(3) {
        transform: rotate(-90deg) translateX(6px);
        -webkit-transform: rotate(-90deg) translateX(6px);
    }
}

.enumenu_ul {
    .caret {
        color: #fff;
        margin-left: 7px;
    }

    padding: 0px;
    list-style: none;
    text-align: right;

    > li {
        display: inline-block;
        vertical-align: middle;
        padding-bottom: 0;
        position: relative;
        margin: rem-calc(0 23 0 0);
        padding: rem-calc(34 0 29 0);
        
        @include bp(lg) {
            padding: rem-calc(25 0 25 0);
            margin: rem-calc(0 10 0 0);
        }
        &.menu-over {
            &:before {
                content: "";
                width: 0;
                height: 0;
                clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                margin: 0 auto;
            }
        }
        &.your-event-btn {
            margin: rem-calc(0 13 0 0);
            padding: 0;
            @include bp(lg) {
                margin: rem-calc(0 10 0 0);
            }
            @include bp(tabletmd) {
                margin: rem-calc(0 0 0 0);
            }
            &.current_page_item {
                a {
                    border-color: $red;
                    color: $red;
                    background-color: transparent;
                    @include bp(tabletmd) {
                        color: $white;
                        border-bottom: 1px solid rgba(255,255,255,0.6);
                    }
                }
            }
            a {
                background-color: $red;
                display: inline-block;
                vertical-align: middle;
                color: $white;
                padding: rem-calc(7 19);
                margin: 0 0 0 0;
                border: 1px solid transparent;
                @include bp(tabletmd) {
                    display: block;
                    background-color: transparent;
                    margin: 0;
                    border-bottom: 1px solid rgba(255,255,255,0.6);
                }
                &:hover {
                    border-color: $red;
                    color: $red;
                    background-color: transparent;
                }
                &:focus {
                    border-color: $red;
                    color: $red;
                    background-color: transparent;
                }
            }
        }

        &.contact-btn {
            padding: 0;
            margin: 0;
            &.current_page_item {
                a {
                    border-color: transparent;
                    color: $white;
                    background-color: $gray;
                    @include bp(tabletmd) {
                        color: $white;
                        border-color: transparent;
                    }
                }
            }
            a {
                background-color: $gray;
                display: inline-block;
                vertical-align: middle;
                color: $white;
                padding: rem-calc(7 26);
                margin: 0 0 0 0;
                border: 1px solid transparent;
                @include bp(tabletmd) {
                    display: block;
                    background-color: transparent;
                    margin: 0;
                    border-bottom: 0;
                }
                &:hover {
                    border-color: $gray;
                    color: $gray;
                    background-color: transparent;
                }
                &:focus {
                    border-color: $gray;
                    color: $gray;
                    background-color: transparent;
                }
            }
        }
    }
    
    li a {
        font-family: $Poppins;
        font-size: rem-calc(16);
        line-height: 1.2;
        padding:rem-calc(0);
        text-transform: capitalize;
        font-weight: normal;
        color: $gray;
        @include bp(lg) {
            font-size: rem-calc(14);
        }
        &:hover {
            color: $red;
            opacity: 1;
        }
    }

    > li {
        &:hover {
            ul {
                &:before {
                    content:'';
                    position: absolute;
                    height: 12px;
                    width: 12px;
                    background: #fff;
                    left: 50%;
                    margin-left: -24px;
                    top: -7px;
                    border-top: 1px solid #272525;
                    border-left: 1px solid #272525;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                    @include bp(tabletmd) {
                        display: none;
                    }
                }
            }
        }
        &:hover > a, &.active > a {
            
        }
        &.current_page_item {
           > a {
                color: $red;
               @include bp(tabletmd) {
                   color: $white;
               }
            }
        }
        &.current-menu-parent {
            > a {
                color: $red;
                @include bp(tabletmd) {
                    color: $white;
                }
            }    
        }
        ul {
            display: none;
        }
    }

    ul li {
        width: 100%;
        margin: 0;
        list-style: none;
        position: relative;
        margin: 0 0 8px 0;
        &.current-menu-item {
            a {
                color: $red;
            }
        }
        @include bp(tabletmd) {
            margin: 0 0 0 0;
            &.current-menu-item {
                a {
                    color: $white;
                }
            }
        }
    }

    &.desk ul {
        top: 101%;
        z-index: 999;
        list-style: none;
        left: -22px;
        position: absolute;
        min-width: 160px;
        margin: 0;
        background-color: $white;
        padding: 8px 0 12px 0;
            
    }
}

/*Sub menu styles*/

.menu.submenu-ul-level1 li a {
    background: rgba(31, 26, 107, 0.8);
    color: #fff;
    padding-left: 90px;
}

.enumenu_ul {
    &.desk {
        li:hover > ul {
            display: block;
            opacity: 1;
        }

        .sb-menu .sb-menu {
            left: 100%;
            top: 0;
        }

        ul li {
            a {
                padding: 8px 22px 0px;
                display: block;
                color: $gray;
                font-size: rem-calc(15);
                text-align: left;
            }

            &:hover > a {
                color: $red;
            }
        }
    }

    .arrow {
        position: absolute;
        right: 10px;
        top: 4px;
        width: 30px;
        height: 30px;
        background: url(../images/plus.png) no-repeat center center;
        cursor: pointer;
        z-index: 999;

        &.up {
            background: url(../images/minus.png) no-repeat center center;
        }
    }
}

@media (max-width: 991px) {
    header {
        padding: 10px 0;
    }

    .enumenu_ul {
        display: none;
        margin: 0;
    }

    .menu-block {
        height: auto;
    }

    .menu-icon {
        display: block;
    }

    .services-detail a.pba-detail {
        border-bottom: 2px solid #d3d9da;
    }

    .enumenu_ul {
        > li {
            width: 100%;
            margin: 0px;
            padding: 0px;
            > a {
                display: block;
                text-align: center;
                padding: rem-calc(8 0);
                border-bottom: 1px solid rgba(255,255,255,0.6);
                color: $white;
                font-size: rem-calc(16);
            }
            ul {
                background-color: #750810;
                margin: 0;
                a {
                    text-align: center;
                    display: block;
                    text-align: center;
                    padding: 8px 0;
                    border-bottom: 1px solid rgba(255, 255, 255, 0.6);
                    font-size: rem-calc(14);
                    color: $white;
                }
            }
        }
        width: 100%;
        position: absolute;
        left: 0;
        top: 100%;
        z-index: 1;
        background-color: $red;
        height: calc(100vh - 70px);
        overflow-y: auto;
    }

    /* Push Menu */

    body {
        &.menuslide_push {
            position: relative;

            &.slidemenuLeft {
                left: 0;
                transition: left 0.5s;
                -webkit-transition: left 0.5s;
                -moz-transition: left 0.5s;
                -ms-transition: left 0.5s;
                -o-transition: left 0.5s;
            }

            &.slidemenuRight {
                right: 0;
                transition: right 0.5s;
                -webkit-transition: right 0.5s;
                -moz-transition: right 0.5s;
                -ms-transition: right 0.5s;
                -o-transition: right 0.5s;
            }
        }

        &.slidemenuRight {
            .enumenu_ul {
                right: -300px;
            }

            &.menu-open .enumenu_ul {
                right: 0;
            }
        }

        &.slidemenuLeft {
            .enumenu_ul {
                left: -300px;
            }

            &.menu-open .enumenu_ul {
                left: 0;
            }
        }

        &.menuslide_push {
            &.slidemenuLeft.menu-open {
                left: 300px;
            }

            &.slidemenuRight.menu-open {
                right: 300px;
            }

            .enumenu_ul {
                background: #000000 none repeat scroll 0 0;
                display: block;
                height: 100%;
                max-height: 100%;
                width: 100%;
                overflow: auto;
                position: fixed;
                transition: all 0.5s ease 0s;
                max-width: 300px;
                top: 0;
            }
        }

        &.menuOverlap .enumenu_ul {
            background: #000000 none repeat scroll 0 0;
            display: block;
            height: 100%;
            max-height: 100%;
            width: 100%;
            overflow: auto;
            position: fixed;
            transition: all 0.5s ease 0s;
            max-width: 300px;
            top: 0;
        }
    }
}