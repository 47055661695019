.site-main .cards-with-icons:not(.full-width) {
    max-width: 1225px;
}
.cards-with-icons {
    position: relative;
    margin: 80px auto 80px!important;
    padding: rem-calc(60 30 33);
    @include bp(tabletXl) {
        padding: rem-calc(40 0);
        margin: 60px auto 60px!important;
    }
    @include bp(tabletsm) {
        padding: rem-calc(30 0);
        margin: 30px auto 30px!important;
    }
    &.destination-cards-with-icons {
        margin: rem-calc(0 0 0 0)!important;
        padding: rem-calc(110 0 78 0);
        @include bp(tabletXl) {
            padding: rem-calc(40 0);
        }
        @include bp(tabletsm) {
            padding: rem-calc(30 0);
        }
        .row {
            padding: rem-calc(0 50);
            @include bp(lg) {
                padding: rem-calc(0 0);
            }
        }
    }
    &.about-card-with-icon {
        margin: rem-calc(0 0 0 0)!important;
        padding: rem-calc(80 0 68 0);
        @include bp(tabletXl) {
            padding: rem-calc(40 0);
        }
        @include bp(tabletsm) {
            padding: rem-calc(30 0);
        }
        .row {
            padding: rem-calc(0 50);
            @include bp(lg) {
                padding: rem-calc(0 0);
            }
        }
    }
    .heading {
        margin: rem-calc(0 0 62 0);
        @include bp(tabletXl) {
            margin: rem-calc(0 0 30 0);
        }
        h2 {
            color: $white;
        }
        p {
            color: $white;
            font-size: rem-calc(20);
            @include bp(lg) {
                font-size: rem-calc(18);
            }
            @include bp(tabletXl) {
                font-size: rem-calc(16);
            }
        }
    }
    .cards-with-icons-list {
        margin: rem-calc(0 0 22 0);
        .cards-with-icons-wrap {
            background-color: $white;
            padding: rem-calc(38 30 39);
            height: 100%;
            @include bp(lg) {
                padding: rem-calc(25 20 25);
            }
            .icons {
                text-align: center;
                margin: rem-calc(0 0 18 0);
                img {
                    width: rem-calc(70);
                    height: rem-calc(70);
                }
            }
            .cont {
                text-align: center;
                h3 {
                    color: $gray;
                }
                p {
                    color: $gray;
                }
            }
        }
    }
}