/*************************
Mixins
*************************/

/************ Placeholder ************/
@mixin placeholder {
  ::-webkit-input-placeholder {@content};
  :-moz-placeholder           {@content};
  ::-moz-placeholder          {@content};
  :-ms-input-placeholder      {@content};
}

/************ Transition *************/
@mixin transition($element, $time, $transition-name) {
	-webkit-transition: $element $time $transition-name;
	-moz-transition: $element $time $transition-name;
	-ms-transition: $element $time $transition-name;
	-o-transition: $element $time $transition-name;
	transition: $element $time $transition-name;
}

/************ Breakpoints *************/
@mixin bp($point) {
    @if $point == xxxsm {
        @media (max-width: 360px) {
            @content;
        }
    } @if $point == xxsm {
		@media (max-width: 360px) {
			@content;
		}
	} @else if $point == xsm {
		@media (max-width: 479px) {
			@content;
		}
	} @else if $point == smmin {
		@media (max-width: 576px) {
			@content;
		}
	} @else if $point == sm {
		@media (max-width: 575px) {
			@content;
		}
	} @else if $point == tabletsm {
		@media (max-width: 767px) {
			@content;
		}
	} @else if $point == tablet {
		@media (max-width: 768px) {
			@content;
		}
	} @else if $point == tabletmin {
		@media (min-width: 768px) {
			@content;
		}
    } @else if $point == tabletmd {
		@media (max-width: 991px) {
			@content;
		}
    } @else if $point == tabletmin {
		@media (min-width: 991px) {
			@content;
		}
	} @else if $point == tabletXl {
		@media (max-width: 1024px) {
			@content;
		}
	} @else if $point == lg {
		@media (max-width: 1199px) {
			@content;
		}
	} @else if $point == lgmin {
		@media (min-width: 1201px) {
			@content;
		}
	} @else if $point == xlthr {
		@media (max-width: 1300px) {
			@content;
		}
	} @else if $point == xlmax {
		@media (max-width: 1365px) {
			@content;
		}
	} @else if $point == xl {
		@media (max-width: 1366px) {
			@content;
		}
	} @else if $point == xxlmax {
        @media (max-width: 1400px) {
            @content;
        }
    } @else if $point == xlmin {
		@media (min-width: 1366px) {
			@content;
		}
    } @else if $point == xxlmin {
		@media (min-width: 1921px) {
			@content;
		}
    } @else if $point == xxxlmin {
        @media (min-width: 2000px) {
            @content;
        }
    }
}
