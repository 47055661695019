.plan-your-event {
    width: 100%;
    position: relative;
    padding-bottom: rem-calc(105);
    @include bp(tabletXl) {
        padding-bottom: rem-calc(60);
    }
    @include bp(tabletsm) {
        padding-bottom: rem-calc(40);
    }
    .plan-your-event-wrap {
        margin: -98px 8px 0;
        background-color: $white;
        padding: rem-calc(30 47 53 73);
        -webkit-box-shadow: 3px 3px 10px 0px rgba(85,86,90,1);
        -moz-box-shadow: 3px 3px 10px 0px rgba(85,86,90,1);
        box-shadow: 3px 3px 10px 0px rgba(85,86,90,1);
        @include bp(lg) {
            margin: -60px 8px 0;
            padding: rem-calc(30 20 30 20);
        }
        @include bp(tabletXl) {
            margin: -45px 8px 0;
            padding: rem-calc(25);
        }
        @include bp(tabletmd) {
            text-align: center;
        }
        h2 {
            margin: rem-calc(0 0 30 2);
            font-size: rem-calc(28);
            color: $gray;
            line-height: normal;
            @include bp(lg) {
                margin: rem-calc(0 0 15 0);
            }
            @include bp(tabletXl) {
                font-size: rem-calc(26);
            }
            @include bp(tabletmd) {
                font-size: rem-calc(24);
            }
        }
        .plan-your-event-form {
            form {
                .validation_error {
                    color: $red;
                    font-size: rem-calc(18);
                    margin: rem-calc(0 0 20 0);

                }
                .gform_body {
                    width: calc(100% - 270px);
                    margin: 0;
                    display: inline-block;
                    vertical-align: middle;
                    @include bp(tabletmd) {
                        width: 100%;
                        margin: rem-calc(0 0 10 0);
                    } 
                    ul {
                        display: flex;
                        flex-wrap: wrap;
                        margin: 0 -7px!important;
                        &:focus { 
                            outline: none;
                        }
                        &.gform_fields {
                            li {
                                padding: 0 7px;
                                width: 36.1%;
                                margin: 0;
                                list-style-type: none;
                                @include bp(tabletmd) {
                                    width: 100%;
                                    margin: rem-calc(0 0 10 0);
                                }
                                > label {
                                    display: none;
                                }
                                &:focus {
                                    outline: none;
                                }
                                &.gfield {
                                    &.event-date {
                                        width: 26.5%;
                                        @include bp(tabletXl) {
                                            width: 27.5%;
                                        }
                                        @include bp(tabletmd) {
                                            width: 100%;
                                            margin: rem-calc(0 0 10 0);
                                        }    
                                    }
                                    .ginput_container {
                                        margin: 0;
                                        position: relative;
                                        &:focus { 
                                            outline: none;
                                        }
                                        input {
                                            width: 100%!important;
                                            border: 1px solid $gray;
                                            font-size: rem-calc(20);
                                            font-weight: normal;
                                            color: $gray;
                                            padding: 9px 10px 9px!important;
                                            margin: 0;
                                            min-width: inherit;
                                            cursor: pointer;
                                            background-image: url(../images/select-icon.svg);
                                            background-repeat: no-repeat;
                                            background-position: 93% center;
                                            background-size: 12px 11px;
                                            -webkit-appearance:none;
                                            appearance:none;
                                            @include bp(tabletXl) {
                                                font-size: rem-calc(18);
                                            }
                                            @include bp(tabletmd) {
                                                background-position: 97% center;
                                                font-size: rem-calc(18);
                                            }
                                            &:focus {
                                                outline: none;
                                            }
                                        }
                                        select {
                                            width: 100%!important;
                                            border: 1px solid $gray;
                                            font-size: rem-calc(20);
                                            font-weight: normal;
                                            color: $gray;
                                            padding: 9px 10px 9px!important;
                                            min-width: inherit;
                                            cursor: pointer;
                                            background-image: url(../images/select-icon.svg);
                                            background-repeat: no-repeat;
                                            background-position: 93% center;
                                            background-size: 12px 11px;
                                            -webkit-appearance:none;
                                            appearance:none;
                                            @include bp(tabletXl) {
                                                font-size: rem-calc(18);
                                            }
                                            @include bp(tabletmd) {
                                                font-size: rem-calc(18);
                                            }
                                            &:focus {
                                                outline: none;
                                            }
                                        }
                                    }
                                }
                                .validation_message {
                                    color: $red;
                                    font-size: rem-calc(15);
                                    line-height: normal;
                                    margin: rem-calc(5 0 0 0);
                                }
                            }
                        }
                    }  
                }
                .gform_footer {
                    margin: 0;
                    padding: rem-calc(0 20 0 0);
                    width: auto;
                    display: inline-block;
                    vertical-align: top;
                    @include bp(tabletmd) {
                        padding: rem-calc(0 0 0 0);
                    }
                    input[type="submit"] {
                        margin: 0;
                        font-size: rem-calc(20);
                        font-weight: $mediumdark;
                        background-color: $red;
                        color: $white;
                        padding: rem-calc(9 17);
                        border: 1px solid transparent;
                        @include transition(0.5s, all, ease-in-out);
                        @include bp(tabletXl) {
                            font-size: rem-calc(18);
                        }
                        &:hover {
                            border-color: $red;
                            background-color: transparent;
                            color: $red;
                            opacity: 1;
                        }
                        &:focus {
                            outline: none;
                            border-color: $red;
                            background-color: transparent;
                            color: $red;
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}