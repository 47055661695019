.destinations-single-banner {
    height: 462px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0;
    position: relative;
    @include bp(lg) {
        height: 380px;
    }
    @include bp(tabletXl) {
        height: 320px;
    }
    @include bp(tabletsm) {
        height: 300px;
    }
    @include bp(sm) {
        height: auto;
    }
    .inner-banner-cont {
        padding: rem-calc(0 0 0 25);
        position: relative;
        z-index: 1;
        font-size: rem-calc(24);
        @include bp(lg) {
            padding: rem-calc(0);
        }
        font-weight: $mediumdark;
        h1 {
            color: $white;
        }
        span {
            color: $white;
            font-size: rem-calc(24);
            font-weight: $mediumdark;
            display: inline-block;
            vertical-align: top;
            margin: 0 0 8px 0;
            @include bp(tabletXl) {
                font-size: rem-calc(20);
            }
            @include bp(tabletsm) {
                font-size: rem-calc(18);
            }
            a {
                span {
                    color: $white;
                    font-size: rem-calc(24);
                    font-weight: $mediumdark;
                    display: inline-block;
                    vertical-align: top;
                    margin: 0 0 8px 0;
                    @include bp(tabletXl) {
                        font-size: rem-calc(20);
                    }
                    @include bp(tabletsm) {
                        font-size: rem-calc(18);
                    }
                }
            }
        }
    }
    &:before {
        content: "";
        background-color: rgba(10,10,50,0.21);
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
    .content {
        padding: 0 0 0 24px;
        max-width: 700px;
        width: 100%;
        span {
            color: $white;
            font-size: rem-calc(24);
            font-weight: $mediumdark;
            display: inline-block;
            vertical-align: top;
            margin: 0 0 8px 0;
            @include bp(tabletXl) {
                font-size: rem-calc(20);
            }
            @include bp(tabletsm) {
                font-size: rem-calc(18);
            }
        }
        h1 {
            color: $white;
        }
    }
}
.content-block {
    &.generic-block {
        padding: rem-calc(92 0 75);
        @include bp(tabletXl) {
            padding: rem-calc(60 0 60);
        }
        @include bp(tabletsm) {
            padding: rem-calc(40 0 40);
        }
        .container {
            padding: 0 0 0 38px;
            @include bp(xl) {
                padding: rem-calc(0);
            }
            @include bp(tabletsm) {
                padding: rem-calc(0 15);
            }
            p {
                font-size: rem-calc(20);
                line-height: rem-calc(30);
                margin-bottom: rem-calc(15);
                max-width: 950px;
                width: 100%;
                @include bp(lg) {
                    font-size: rem-calc(18);
                    line-height: 1.5;
                }
                @include bp(tabletXl) {
                    font-size: rem-calc(16);
                }
            }
        }
    }
}