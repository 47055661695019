.post-cards {
    position: relative;
    padding: rem-calc(114 0 95);
    overflow: hidden;
    @include bp(lg) {
        padding: rem-calc(80 0 80);
    }
    @include bp(tabletXl) {
        padding: rem-calc(40 0);
    }
    @include bp(tabletsm) {
        padding: rem-calc(30 0);
    }
    &.event-pots-card {
        padding: rem-calc(132 0 20);
        @include bp(lg) {
            padding: rem-calc(80 0 80);
        }
        @include bp(tabletXl) {
            padding: rem-calc(40 0);
        }
        @include bp(tabletsm) {
            padding: rem-calc(30 0);
        }
        .heading {
            margin: rem-calc(0 0 70 0);
            @include bp(tabletXl) {
                margin: rem-calc(0 0 30 0);
            }
        }
        .post-cards-list {
            margin-bottom: rem-calc(30);
            .post-cards-wrap {
                -webkit-box-shadow: 0px 1px 3px 1px rgba(39, 37, 37, 0.26);
                -moz-box-shadow: 0px 1px 3px 1px rgba(39, 37, 37, 0.26);
                box-shadow: 0px 1px 3px 1px rgba(39, 37, 37, 0.26);
                padding-bottom: rem-calc(0);
                @include transition(0.4s, all, ease-in-out);
                cursor: pointer;
                &:hover {
                    -webkit-box-shadow: 0px 3px 6px 3px rgba(39, 37, 37, 0.26);
                    -moz-box-shadow: 0px 3px 6px 3px rgba(39, 37, 37, 0.26);
                    box-shadow: 0px 3px 6px 3px rgba(39, 37, 37, 0.26);
                }
                .post-cards-cont-wrap {
                    .post-cards-cont {
                        padding: rem-calc(39 45 40 26);
                        @include bp(tabletXl) {
                            padding: rem-calc(20 20 20 20);
                        }
                    }
                }
            }
        }
    }
    &.destinations-single-post-cards {
        position: relative;
        padding: rem-calc(114 0 80);
        &:before {
            content: "";
            background-image: url('../images/destinations-single-post-card-bg.svg');
            background-repeat: no-repeat;
            width: 860px;
            height: 100%;
            background-position: right top;
            background-size: cover;
            position: absolute;
            top: 0;
            right: 0;
            z-index: 0;
            opacity: 0.35;
            @include bp(lg) {
                width: 700px;
            }
            @include bp(tabletXl) {
                width: 600px;
            }
            @include bp(tabletmd) {
                width: 500px;
                background-size: contain;
            }
        }
        @include bp(lg) {
            padding: rem-calc(80 0 80);
        }
        @include bp(tabletXl) {
            padding: rem-calc(40 0);
        }
        @include bp(tabletsm) {
            padding: rem-calc(30 0);
        }
        .heading {
            margin: 5px 0 44px 8px;
            h2 {
                color: $white;
            }
        }
        .post-cards-list {
            .post-cards-wrap {
                .post-cards-bg-wrap {
                    .post-cards-bg {
                        padding-bottom: 61.25%;
                    }
                }
                .post-cards-cont-wrap {
                    .post-cards-cont {
                        padding: rem-calc(38 25 9 23);
                        @include bp(tabletXl) {
                            padding: rem-calc(20 20 20 20);
                        }
                        h3 {
                            letter-spacing: -0.3px;
                        }
                    }
                }
            }
        }
    }
    &.post-cards-two-col {
        padding: rem-calc(135 0 60);
        @include bp(lg) {
            padding: rem-calc(80 0 60);
        }
        @include bp(tabletXl) {
            padding: rem-calc(60 0 60);
        }
        @include bp(tabletsm) {
            padding: rem-calc(40 0);
        }
        .heading {
            margin: rem-calc(0 0 70 0);
            @include bp(tabletXl) {
                margin: rem-calc(0 0 30 0);
            }
        }
        .post-cards-list {
            margin-bottom: rem-calc(88);
            @include bp(tabletXl) {
                margin-bottom: rem-calc(50);
            }
            @include bp(tabletsm) {
                margin-bottom: rem-calc(30);
            }
            &:nth-of-type(2n) {
                .post-cards-wrap {
                    padding: 0 85px 0 20px;
                    @include bp(tabletXl) {
                        padding: rem-calc(0 0 70 0);
                    }
                }
            }
            .post-cards-wrap {
                padding: 0 31px 85px 73px;
                background-color: transparent;
                box-shadow: none;
                background-color: transparent;
                box-shadow: none;
                @include bp(tabletXl) {
                    padding: rem-calc(0 0 70 0);
                }
                &:hover {
                    box-shadow: none;
                }
                .post-cards-bg-wrap {
                    .post-cards-bg {
                        padding-bottom: 75%;
                    }
                }
                .post-cards-cont-wrap {
                    .post-cards-cont {
                        padding: rem-calc(27 0 0 0);
                        @include bp(tabletsm) {
                            padding: rem-calc(15 0 0 0);
                        }
                    }
                    .post-cards-btn {
                        padding: 0 2px;
                        bottom: 11px;
                    }
                }
            }
        }
    }
    .heading {
        margin: rem-calc(0 0 44 0);
        @include bp(tabletXl) {
            margin: rem-calc(0 0 30 0);
        }
        p {
            font-size: rem-calc(20);
            font-weight: $normal;
            @include bp(tabletXl) {
                font-size: rem-calc(18);
            }
            @include bp(tabletsm) {
                font-size: rem-calc(16);
            }
        }
    }
    .post-cards-list {
        margin-bottom: rem-calc(30);
        .post-cards-wrap {
            margin: rem-calc(0 0 0 8);
            @include bp(lg) {
                margin: rem-calc(0 0 0 0);
            }
                height: 100%;
                display: inline-block;
                vertical-align: middle;
                height: 100%;
                background-color: $white;
                -webkit-box-shadow: 0px 2px 5px 3px rgba(39,37,37,0.16);
                -moz-box-shadow: 0px 2px 5px 3px rgba(39,37,37,0.16);
                box-shadow: 0px 2px 5px 3px rgba(39,37,37,0.16);
                padding-bottom: rem-calc(106);
                width: 100%;
                @include bp(tabletXl) {
                    padding-bottom: rem-calc(70);
                }
                @include transition(0.4s, all, ease-in-out);
                &:hover {
                    -webkit-box-shadow: 0px 3px 7px 4px rgba(39, 37, 37, 0.26);
                    -moz-box-shadow: 0px 3px 7px 4px rgba(39, 37, 37, 0.26);
                    box-shadow: 0px 3px 7px 4px rgba(39, 37, 37, 0.26);
                }
                &:focus {
                    outline: none;
                }
                .post-cards-bg-wrap {
                    position: relative;
                    overflow: hidden;
                    -webkit-transition:-webkit-transform 0.7s linear;
                    transition:-webkit-transform 0.7s linear;
                    transition:transform 0.7s linear;
                    transition:transform 0.7s linear, -webkit-transform 0.7s linear;
                    @include transition(0.5s, all, ease-in-out);
                    &:hover {
                        .post-cards-bg {
                            -webkit-transform:scale(1.2);
                            -ms-transform:scale(1.2);
                            transform:scale(1.2);
                            -webkit-transition-duration:2s;
                            transition-duration:2s;
                        }
                    }
                    &:before {
                        content: "";
                        background-color: inherit;
                        width: 105%;
                        height: 105%;
                        position: absolute;
                        left: -10px;
                        top: -10px;
                        right: 0px;
                        z-index: 0;
                    }
                    .post-cards-bg {
                        padding-bottom: 60.25%;
                        background-position: center center;
                        background-repeat: no-repeat;
                        background-size: cover;
                        position: relative;
                        @include transition(0.5s, all, ease-in-out);
                    }
                }
                .post-cards-cont-wrap {
                    .post-cards-cont {
                        padding: rem-calc(39 20 40 26);
                        @include bp(tabletXl) {
                            padding: rem-calc(20 20 20 20);
                        }
                    }
                .post-cards-btn {
                    position: absolute;
                    bottom: rem-calc(41);
                    padding: rem-calc(0 26);
                    @include bp(tabletXl) {
                        bottom: rem-calc(20);
                    }
                    a {
                        display: inline-block;
                        vertical-align: middle;
                        font-family: $Poppins;
                        font-size: rem-calc(20);
                        line-height: 1.5;
                        font-weight: $mediumdark;
                        border: 1px solid transparent;
                        text-transform: capitalize;
                        padding: rem-calc(8 32);
                        background-color: $red;
                        color: $white;
                        @include transition(0.5s, all, ease-in-out);
                        @include bp(tabletXl) {
                            font-size: rem-calc(18);
                        }
                        @include bp(tabletsm) {
                            font-size: rem-calc(16);
                        }
                        &:hover {
                            border-color: $red;
                            color: $red;
                            background-color: transparent;
                        }
                        &:focus {
                            border-color: $red;
                            color: $red;
                            background-color: transparent;
                            outline: none;
                        }
                    } 
                }
            }
        }
    }
}