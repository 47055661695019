.site-main > .full-width-image-block:not(.full-width) {
    max-width: 100%;
}
.full-width-image-block {
    position: relative;
    .full-width-image-block-bg {
        padding-bottom: 37.8%;
        background-position: center top;
        background-repeat: no-repeat;
        background-size: cover;
        background-attachment: fixed;
        @include bp(tabletmd) {
            padding-bottom: 45%;
        }
    }
}