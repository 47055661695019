.event-type-block {
    position: relative;
    padding: rem-calc(40 0 25 0);
    .event-type-list {
        margin-bottom: rem-calc(92);
        &.right-bg {
            .event-bg-wrap {
                order: 2;
            }
            .event-cont {
                margin: rem-calc(0 35 0 12);
            }
        }
        .event-bg {
            padding-bottom: 72.4%;
            background-position: center center;
            background-size: cover;
            background-repeat: no-repeat;
            margin: rem-calc(0 10 0 9);
        }
        .event-cont {
            margin: rem-calc(25 15 0 32);
            p {
                margin: rem-calc(0 0 10 0);
            }
        }
    }
}