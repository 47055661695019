.site-main > .call-to-action:not(.full-width) {
    max-width: 1220px;
    @include bp(xlmax) {
        max-width: 1090px;
    }
    @include bp(lg) {
        max-width: 910px;
    }
    @include bp(tabletmd) {
        max-width: 670px;
    }
    @include bp(tabletsm) {
        max-width: 495px;
    }
 
}
.call-to-action {
    position: relative;
    .call-to-action-wrap {
        padding: rem-calc(85 0 82);
        margin: rem-calc(0 8);
        text-align: center;
        @include bp(lg) {
            padding: rem-calc(60 15 60);
            margin: rem-calc(0);
        }
        @include bp(tabletXl) {
            padding: rem-calc(40 15 40);
        }
        .call-to-action-cont {
            max-width: rem-calc(950);
            margin: 0 auto 55px;
            width: 100%;
            @include bp(tabletXl) {
                margin: 0 auto 25px;
            }
            h2 {
                color: $white;
                font-size: rem-calc(48);
                font-weight: $mediumdark;
                margin: rem-calc(0 0 15 0);
                @include bp(lg) {
                    font-size: rem-calc(36);
                }
                @include bp(tabletXl) {
                    font-size: rem-calc(30);
                }
            }
            p {
                color: $white;
                font-size: rem-calc(20);
                line-height: 1.5;
                font-weight: normal;
                @include bp(lg) {
                    font-size: rem-calc(18);
                }
                @include bp(tabletXl) {
                    font-size: rem-calc(16);
                }
            }
        }
        .call-to-action-btn {
            a {
                &:hover {
                    border-color: $white;
                    color: $red;
                    background-color: $white;
                    opacity: 1;
                }
            }
        }
    }
}