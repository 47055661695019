.featured-resources {
    position: relative;
    padding: rem-calc(40 0 55);
    @include bp(tabletXl) {
        padding: rem-calc(40 0);
    }
    @include bp(tabletsm) {
        padding: rem-calc(30 0);
    }
    &.about-featured-resources {
        padding: rem-calc(112 0 65);
        @include bp(tabletXl) {
            padding: rem-calc(40 0);
        }
        @include bp(tabletsm) {
            padding: rem-calc(30 0);
        }
    }
    &.featured-resources-online-streaming {
        padding: rem-calc(95 0 62);
        @include bp(tabletXl) {
            padding: rem-calc(40 0);
        }
        @include bp(tabletsm) {
            padding: rem-calc(30 0);
        }
    }
    &.featured-resources-av-production {
        padding: 132px 0 62px;
        @include bp(tabletXl) {
            padding: rem-calc(60 0);
        }
        @include bp(tabletsm) {
            padding: rem-calc(40 0);
        }
    }
    .heading {
        margin: rem-calc(0 0 70 0);
        @include bp(tabletsm) {
            margin: rem-calc(0 0 40 0);;
        }
    }
    .featured-resources-list {
        margin-bottom: rem-calc(30);
        .featured-resources-wrap {
            margin: rem-calc(0 0 0 4);
            height: 100%;
            a {
                display: inline-block;
                vertical-align: top;
                width: 100%;
                height: auto;
                &:hover {
                    h3 {
                        color: $red;
                    }
                }
                .featured-resources-bg-wrap {
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                    -webkit-transition:-webkit-transform 0.7s linear;
                    transition:-webkit-transform 0.7s linear;
                    transition:transform 0.7s linear;
                    transition:transform 0.7s linear, -webkit-transform 0.7s linear;
                    @include transition(0.5s, all, ease-in-out);
                    &:hover {
                        .featured-resources-bg {
                            -webkit-transform:scale(1.2);
                            -ms-transform:scale(1.2);
                            transform:scale(1.2);
                            -webkit-transition-duration:2s;
                            transition-duration:2s;
                        }
                    }
                    .featured-resources-bg {
                        padding-bottom: 60.25%;
                        background-position: center center;
                        background-repeat: no-repeat;
                        background-size: cover;
                        position: relative;
                        @include transition(0.5s, all, ease-in-out);
                    }
                }
                h3 {
                    margin: rem-calc(20 50 0 4);
                    line-height: 1.4;
                    color: $gray;
                    font-size: rem-calc(24);
                    @include bp(lg) {
                        margin: rem-calc(20 0 0 0);
                    }
                    @include bp(tabletXl) {
                        font-size: rem-calc(20);
                    }
                }
            }
        }
    }
}