.contact-us-block {
    position: relative;
    padding: rem-calc(82 0 125);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    @include bp(tabletXl) {
        padding: rem-calc(60 0 70);
    }
    &:before {
        content: "";
        background-color: rgba(10,10,51,0.7);
        position: absolute;
        left: 0;
        top:0;
        width: 100%;
        height: 100%;
    }
    .contact-us-cont {
        max-width: 750px;
        width: 100%;
        margin: 0 0 78px 8px;
        @include bp(tabletmd) {
            margin: 0 0 40px 0px;
        }
        span {
            display: inline-block;
            vertical-align: top;
            font-size: rem-calc(24);
            font-weight: $mediumdark;
            line-height: normal;
            color: $white;
            margin: rem-calc(0 0 25 0);
            @include bp(tabletmd) {
                font-size: rem-calc(20);
                margin: rem-calc(0 0 15 0);
            }
        }
        h1 {
            color: $white;
            margin: rem-calc(0 0 13 0);
        }
        p {
            color: $white;
            font-size:rem-calc(20);
            line-height: 1.54;
            @include bp(tabletXl) {
                font-size: rem-calc(18);
            }
        }
    }
    .contact-us-form {
        margin: 0 0 0 8px;
        @include bp(tabletmd) {
            margin: 0 0 0 0;
        }
        .gform_confirmation_message {
            font-size: 22px;
            font-weight: 600;
            text-align: center;
            line-height: normal;
            color: $white
        }
        .gform_wrapper {
            background-color: transparent;
            form {
                .validation_error {
                    color: $white;
                    font-size: 16px;
                    margin: 0 0 10px 0;
                    line-height: normal;
                }
                .gform_body {
                    ul {
                        li {
                            &.gfield {
                                width: 100%;
                                list-style-type: none;
                                margin: rem-calc(0 0 15 0);
                                label {
                                    &.gfield_label {
                                        display: none;
                                    }
                                }
                                input {
                                    width: 100%;
                                    border: 1px solid transparent;
                                    box-shadow: none;
                                    padding: 10px;
                                    border-radius: 0;
                                    font-size: rem-calc(18);
                                    line-height: normal;
                                    font-weight: $normal;
                                    color: #c6c5c5;
                                    -webkit-appearance: none;
                                    &:focus {
                                        outline: none;
                                    }
                                }
                                textarea {
                                    height: 145px;
                                    padding: 17px 10px 0;
                                    font-size: rem-calc(18);
                                    line-height: normal;
                                    font-weight: $normal;
                                    color: #c6c5c5;
                                    resize: none;
                                    &:focus {
                                        outline: none;
                                    }
                                }
                                .ginput_container_checkbox {
                                    ul {
                                        li {
                                            list-style-type: none;
                                        }
                                    }
                                }
                                .validation_message {
                                    color: $white;
                                    font-size: rem-calc(14);
                                    line-height: normal;
                                    margin: rem-calc(5 0 0 0);
                                }
                            }
                        }
                    }
                }
                .gform_footer {
                    margin: 38px 0 0 0;
                    @include bp(tabletsm) {
                        margin: 20px 0 0 0;
                    }
                    input[type='submit'] {
                        color: $white;
                        background-color: $red;
                        padding: rem-calc(9 55);
                        font-size: rem-calc(20);
                        opacity: 1;
                        font-weight: $mediumdark;
                        border: 1px solid transparent;
                        &:hover {
                            background-color: transparent;
                            border-color: $red;
                            color: $white;
                        }
                        &:focus {
                            background-color: transparent;
                            border-color: $red;
                            color: $white;
                            outline: none;
                        }
                    }
                }
            }
        }
    }
    .contact-us-number {
        width: 88%;
        height: calc(100% - 139px);
        background-color: $red;
        margin: 0 0 0 35px;
        padding: 67px 51px 30px;
        @include bp(tabletmd) {
            width: 100%;
            padding: 30px 30px 30px;
            margin: 0;
        }
        @include bp(tabletsm) {
            width: 100%;
            padding: 30px 30px 30px;
            height: auto;
            margin: 30px 0 0 0;
        }
        ul {
            li {
                list-style-type: none;
                margin: 0 0 72px 0;
                &:last-child {
                    margin: 0;
                }
                strong {
                    font-size: rem-calc(28);
                    font-weight: $mediumdark;
                    color: $white;
                    display: inline-block;
                    vertical-align: top;
                    margin: 0 0 16px 0;
                    @include bp(tabletmd) {
                        font-size: rem-calc(24);
                    }
                    @include bp(tabletsm) {
                        display: block;
                        font-size: rem-calc(22);
                    }
                }
                a {
                    font-size: rem-calc(22);
                    font-weight: $mediumdark;
                    color: $white;
                    display: inline-block;
                    vertical-align: top;
                    line-height: normal;
                    padding-bottom: 2px;
                    border-bottom: 1px solid $white;
                    @include bp(tabletmd) {
                        font-size: rem-calc(20);
                    }
                    @include bp(tabletsm) {
                        font-size: rem-calc(18);
                    }
                    &:hover {
                        color: $gray;
                        border-bottom-color: $gray;
                    }
                }
            }
        }
    }
}