.meet-the-team {
    position: relative;
    padding: rem-calc(130 0 32);
    @include bp(lg) {
        padding: rem-calc(80 0);
    }
    @include bp(tabletXl) {
        padding: rem-calc(60 0);
    }
    @include bp(tabletsm) {
        padding: rem-calc(40 0);
    }
    .heading {
        margin: 0 auto 63px;
        max-width: 860px;
        width: 100%;
        text-align: center;
        @include bp(tabletXl) {
            margin: rem-calc(0 0 30 0);
        }
    }
    .meet-the-team-list {
        margin: rem-calc(0 0 82 0);
        @include bp(tabletsm) {
            margin: rem-calc(0 0 40 0);
        }
        .meet-the-team-wrap {
            .meet-the-team-bg {
                background-repeat: no-repeat;
                background-size: contain;
                background-position: bottom center;
                border: 1px solid $gray;
                height: 287px;
                width: 100%;
                @include bp(xl) {
                    height: 250px;
                }
                @include bp(tabletsm) {
                    height: 230px;
                }
                @include bp(sm) {
                    height: 180px;
                }
                @include bp(xsm) {
                    height: 150px;
                }
            }
            h3 {
                margin: rem-calc(28 0 0 6);
                font-size: rem-calc(24);
                line-height: 1.2;
                @include bp(tabletXl) {
                    font-size: rem-calc(20);
                    margin: rem-calc(15 0 0 0);
                }
                @include bp(tabletsm) {
                    font-size: rem-calc(18);
                }
            }
            span {
                font-size: rem-calc(20);
                font-weight: $normal;
                display: inline-block;
                vertical-align: top;
                margin: rem-calc(2 0 0 6);
                @include bp(tabletsm) {
                    margin: rem-calc(2 0 0 0);
                }
            }
        }
    }
}