.project-gallery {
    position: relative;
    padding: rem-calc(0 0 120 0);
    @include bp(tabletXl) {
        padding: rem-calc(0 0 60);
    }
    @include bp(tabletsm) {
        padding: rem-calc(0 0 40);
    }
    .project-gallery-list {
        margin-bottom: rem-calc(14);
        .project-gallery-wrap {
            a {
                display: inline-block;
                vertical-align: top;
                width: 100%;
                height: 100%;
                &:hover {
                    .project-gallery-bg {
                        .overlay {
                            top: 0;
                            opacity: 1;
                        }
                    }  
                }
                .project-gallery-bg {
                    padding-bottom: 94.7%;
                    background-position: top center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    position: relative;
                    overflow: hidden;
                    @include bp(tabletmd) {
                        padding-bottom: 75%;
                    }
                    @include bp(tabletsm) {
                        padding-bottom: 100%;
                    }
                    @include bp(sm) {
                        padding-bottom: 80%;
                    }
                    .overlay {
                        position: absolute;
                        left: 0;
                        top: 100%;
                        width: 100%;
                        height: 100%;
                        opacity: 0;
                        @include transition(0.4s, all, ease-in-out);
                        &:before {
                            content: "";
                            background-color: rgba(39,37,37,0.9);
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 100%;
                            height: 100%;
                        }
                        .project-gallery-cont {
                            z-index: 1;
                            position: absolute;
                            left: 0;
                            right: 0;
                            margin: 0 auto;
                            text-align: center;
                            top: 53%;
                            transform: translateY(-50%);
                            -webkit-transform: translateY(-50%);
                            -ms-transform: translateY(-50%);
                            padding: rem-calc(0 15);
                            h4 {
                                color: $white;
                                margin: rem-calc(0 0 16 22);
                                font-weight: 400;
                                font-size: rem-calc(24);
                                line-height: 1.45;
                                @include bp(tabletmd) {
                                    font-size: rem-calc(20);
                                    margin: rem-calc(0 0 0 0);
                                }
                            }
                            span {
                                display: inline-block;
                                vertical-align: top;
                                padding: 10px;
                                position: relative;
                                width: rem-calc(48);
                                height: rem-calc(48);
                                color: $white;
                                border: 1px solid $white;
                                margin: 22px 0 0 0;
                                font-size: rem-calc(28);
                                font-weight: normal;
                                line-height: rem-calc(30);
                                @include bp(tabletmd) {
                                    padding: 5px;
                                    width: rem-calc(40);
                                    height: rem-calc(40);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .project-gallery-btn {
        margin: rem-calc(53 0 0 0);
        @include bp(tabletmd) {
            margin: rem-calc(10 0 0 0);
        }
        a {
            border: 1px solid $red;
            color: $red;
            &:hover {
                background-color: $red;
                color: $white;
                border-color: $red;
            }
            &:focus {
                background-color: $red;
                color: $white;
                border-color: $red;
            }
        }
    }
}