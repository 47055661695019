.brands-logo {
    position: relative;
    padding: rem-calc(84 0 106);
    @include bp(tabletXl) {
        padding: rem-calc(40 0);
    }
    @include bp(tabletsm) {
        padding: rem-calc(30 0);
    }
    .brands-logo-wrap {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 93px 0 0 0;
        @include bp(tabletXl) {
            margin: 60px 0 0 0;
        }
        @include bp(tabletsm) {
            margin: 40px 0 0 0;
        }
        .brands-logo-list {
            width: 20%;
            border-right: 1px solid #d3d3d3;
            border-bottom: 1px solid #d3d3d3;
            padding: 0 16px;
            text-align: center;
            min-height: 160px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            @include bp(tabletmd) {
                width: 25%;
            }
            @include bp(tabletsm) {
                width: 33.33%;
            }
            @include bp(xsm) {
                width: 50%;
            }
            &:last-child {
                border-right: 0;
                border-bottom: 0;
            }
            &:nth-of-type(5n+5) {
                border-right: 0;
                @include bp(tabletmd) {
                    border-right: 1px solid #d3d3d3; 
                }
            }
            &:nth-of-type(4n+4) {
                @include bp(tabletmd) {
                    border-right: 0;
                }
                @include bp(tabletsm) {
                    border-right: 1px solid #d3d3d3;
                }
            }
            &:nth-of-type(3n+3) {
                @include bp(tabletsm) {
                    border-right: 0;
                }
                @include bp(xsm) {
                    border-right: 1px solid #d3d3d3;
                }
            }
            &:nth-of-type(2n+2) {
                @include bp(xsm) {
                    border-right: 0;
                }
                
            }
            &:nth-last-child(5) {
                border-bottom: 0;
                @include bp(tabletmd) {
                    border-bottom: 1px solid #d3d3d3; 
                }
            }
            &:nth-last-child(4) {
                border-bottom: 0;
                @include bp(tabletmd) {
                    border-bottom: 1px solid #d3d3d3; 
                }
            }
            &:nth-last-child(3) {
                border-bottom: 0;
                @include bp(tabletmd) {
                    border-bottom: 1px solid #d3d3d3; 
                }
            }
            &:nth-last-child(2) {
                border-bottom: 0;
                @include bp(tabletsm) {
                    border-bottom: 1px solid #d3d3d3;
                }
                @include bp(xsm) {
                    border-bottom: 0;
                }
            }
            /*img {
                height: 110px;
                width: 110px;
            }*/
        }
    }
}