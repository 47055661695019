/*************************
Font Family
*/


$Poppins: 'Poppins';


/* ----- Font weight ------- */
$thin: 200;
$light: 300;
$normal: 400;
$medium: 500;
$mediumdark: 600;
$bold: 700;
$heavy: 800;
$blackwht: 900;


/* ------------------ */

/* Color */

$white: #fff;
$black: #000;
$dark-black: #272525;
$gray: #54565a;
$red: #f12535;
$red-dark: #d12b38;
$blue: #2f355d;
$mineshaft: #212620;
$blue-nav: #28325e;


/*Background Color*/
.background-navy {
    background-color: #2f355d;
}
.background-mineshaft {
    background-color: $mineshaft;
}
.background-gray, .has-gray-background-color {
    background-color: $gray;
}
.background-whitesmoke, .has-whitesmoke-background-color {
    background-color: #f6f6f7;   
}

/*Font Color*/
.color-gray {
    h2 {
        color: $gray;
    } 
    h3 {
        color: $gray;
    }
    p {
        color: $gray;
    }
}
.color-orange {
    h2 {
        color: $red;
    } 
    h3 {
        color: $red;
    }
    p {
        color: $red;
    }
}
.color-black {
    h2 {
        color: $black;
    } 
    h3 {
        color: $black;
    }
    p {
        color: $black;
    }
}
.color-white {
    h2 {
        color: $white;
    } 
    h3 {
        color: $white;
    }
    p {
        color: $white;
    }
}

/*fonts face*/
@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins-SemiBold.eot');
    src: url('../fonts/Poppins-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins-SemiBold.woff2') format('woff2'),
        url('../fonts/Poppins-SemiBold.woff') format('woff'),
        url('../fonts/Poppins-SemiBold.ttf') format('truetype'),
        url('../fonts/Poppins-SemiBold.svg#Poppins-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins-Regular.eot');
    src: url('../fonts/Poppins-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins-Regular.woff2') format('woff2'),
        url('../fonts/Poppins-Regular.woff') format('woff'),
        url('../fonts/Poppins-Regular.ttf') format('truetype'),
        url('../fonts/Poppins-Regular.svg#Poppins-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins-ExtraLight.eot');
    src: url('../fonts/Poppins-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins-ExtraLight.woff2') format('woff2'),
        url('../fonts/Poppins-ExtraLight.woff') format('woff'),
        url('../fonts/Poppins-ExtraLight.ttf') format('truetype'),
        url('../fonts/Poppins-ExtraLight.svg#Poppins-ExtraLight') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins-Light.eot');
    src: url('../fonts/Poppins-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins-Light.woff2') format('woff2'),
        url('../fonts/Poppins-Light.woff') format('woff'),
        url('../fonts/Poppins-Light.ttf') format('truetype'),
        url('../fonts/Poppins-Light.svg#Poppins-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins-Bold.eot');
    src: url('../fonts/Poppins-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins-Bold.woff2') format('woff2'),
        url('../fonts/Poppins-Bold.woff') format('woff'),
        url('../fonts/Poppins-Bold.ttf') format('truetype'),
        url('../fonts/Poppins-Bold.svg#Poppins-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins-Medium.eot');
    src: url('../fonts/Poppins-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins-Medium.woff2') format('woff2'),
        url('../fonts/Poppins-Medium.woff') format('woff'),
        url('../fonts/Poppins-Medium.ttf') format('truetype'),
        url('../fonts/Poppins-Medium.svg#Poppins-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins-ExtraBold.eot');
    src: url('../fonts/Poppins-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins-ExtraBold.woff2') format('woff2'),
        url('../fonts/Poppins-ExtraBold.woff') format('woff'),
        url('../fonts/Poppins-ExtraBold.ttf') format('truetype'),
        url('../fonts/Poppins-ExtraBold.svg#Poppins-ExtraBold') format('svg');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins-Thin.eot');
    src: url('../fonts/Poppins-Thin.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins-Thin.woff2') format('woff2'),
        url('../fonts/Poppins-Thin.woff') format('woff'),
        url('../fonts/Poppins-Thin.ttf') format('truetype'),
        url('../fonts/Poppins-Thin.svg#Poppins-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins-Black.eot');
    src: url('../fonts/Poppins-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins-Black.woff2') format('woff2'),
        url('../fonts/Poppins-Black.woff') format('woff'),
        url('../fonts/Poppins-Black.ttf') format('truetype'),
        url('../fonts/Poppins-Black.svg#Poppins-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}



@font-face {
    font-family: 'Wingdings 3';
    src: url('../fonts/Wingdings3.eot');
    src: url('../fonts/Wingdings3.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Wingdings3.woff2') format('woff2'),
        url('../fonts/Wingdings3.woff') format('woff'),
        url('../fonts/Wingdings3.ttf') format('truetype'),
        url('../fonts/Wingdings3.svg#Wingdings3') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


.container{
	@include bp(xlmin) {
        max-width:rem-calc(1267);
        /*max-width:rem-calc(1253);*/
	}
}

div:focus {
    outline: none;
}
section {
    width: 100%;
    &:focus {
        outline: none;
    }
}
ul {
    li {
        &:focus {
            outline: none;
        }
    }
}

@media all and (-ms-high-contrast:none){
    .videobg_child video{ height: auto!important; }
    .image-cards-block .cards-block-bg:after {
        content: "";
        opacity: 0.6;
    }
    .image-cards-block .cards-block-bg:before {
        content: "";
        background-color: rgba(253,185,19,0.5);
        opacity: 1;
    }
}

_:-ms-lang(x), .videobg_child video{ height: auto!important; }

.no-gutters {
  margin-right: 0;
  margin-left: 0;
  > .col,
  > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}
.no-padding {
    padding-left: 0;
    padding-right: 0;
}


img {
    height: auto;
    max-width: 100%;
    vertical-align: middle;
}

body {
    font-family: $Poppins;
	font-size: rem-calc(18);
	line-height: rem-calc(28);
	font-weight: normal;
    color: $gray;
    overflow-x: hidden; 
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-text-size-adjust: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	-webkit-tap-highlight-color: transparent;
}

a {
    color: $red;
	text-decoration: none;
	@include transition(0.5s, all, ease-in-out);
	&:hover, &:focus {
		background: none;
	    text-decoration: none;
		box-shadow: none;
	}
    &:focus {
        outline: none;
    }
    &:active {
        outline: none;    
    }
}
a:not(.button) {
    text-decoration: none;
    @include transition(0.5s, all, ease-in-out);
}

table tr {
    display: table-row;
    margin-bottom: 0;
}
table th, table td {
    display: table-cell;
}


br {
    @include bp(xl) {
        display: none;
    }
}

p {
    font-size: rem-calc(18);
    font-weight: normal;
    line-height: 1.60;
    color: $gray;
    margin-bottom: rem-calc(20);
    @include bp(lg) {
        font-size: rem-calc(18);
        margin-bottom: rem-calc(15);
        line-height: 1.5;
    }
    @include bp(tabletXl) {
        font-size: rem-calc(16);
    }
}
ul {
    padding: 0;
    margin: 0;
    li {
        
    }
}

h1 {
    font-size: rem-calc(62);
    line-height: rem-calc(72);
    font-weight: normal;
    color: $red;
    @include bp(lg) {
        font-size: rem-calc(46);
        line-height: 1.2;
        margin: rem-calc(0 0 10 0);
    }
    @include bp(tabletXl) {
        font-size: rem-calc(38);
        line-height: 1.2;
        margin: rem-calc(0 0 10 0);
    }
    @include bp(tabletsm) {
        font-size: rem-calc(32);
    }
}
h2 {
    font-size: rem-calc(48);
    line-height: rem-calc(58);
    font-weight: $mediumdark;
    color: $red;
    @include bp(lg) {
        font-size: rem-calc(38);
        line-height: 1.2;
        margin: rem-calc(0 0 15 0);
    }
    @include bp(tabletXl) {
        font-size: rem-calc(32);
        line-height: 1.2;
        margin: rem-calc(0 0 15 0);
    }
    @include bp(tabletsm) {
        font-size: rem-calc(28);
        line-height: 1.2;
        margin: rem-calc(0 0 15 0);
    }
}
h3 {
    font-size: rem-calc(28);
    line-height: rem-calc(38);
    font-weight: $mediumdark;
    color: $red;
    @include bp(tabletXl) {
        font-size: rem-calc(26);
        line-height: 1.2;
        margin: rem-calc(0 0 15 0);
    }
    @include bp(tabletsm) {
        font-size: rem-calc(24);
    }
}


.pb-0 {
	padding-bottom: 0 !important;
}

.pt-0 {
	padding-top: 0 !important;
}

/*Button Start*/
.btn {
    display: inline-block;
    vertical-align: middle;
    font-family: $Poppins;
    font-size: rem-calc(20);
    line-height: 1.5;
    font-weight: $mediumdark;
    border: 1px solid transparent;
    text-transform: capitalize;
    padding: rem-calc(10 35);
    border-radius: 0;
    @include bp(tabletmd) {
        font-size: rem-calc(16);
    }
    @include bp(tabletsm) {
        font-size: rem-calc(14);
    }
    &:focus {
        outline: none;
        box-shadow: none;
        text-decoration: none;
    }
    &:hover {
        text-decoration: none;        
    }
}

.red-btn {
    background-color: $red;
    color: $white;
    &:hover {
        border-color: $red;
        color: $red;
        background-color: transparent;
    }
    &:focus {
        border-color: $red;
        color: $red;
        background-color: transparent;
    }
}
