.home-hero-banner {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: rem-calc(653);
    width: 100%;
    @include bp(lg) {
        height: rem-calc(550);
    }
    @include bp(tabletmd) {
        height: rem-calc(450);
    }
    &:before {
        content: "";
        background: -moz-linear-gradient(top,  rgba(3,3,49,0.06) 0%, rgba(3,3,49,0.07) 19%, rgba(3,3,49,0.13) 46%, rgba(3,3,49,0.25) 76%, rgba(3,3,49,0.38) 100%); 
        background: -webkit-linear-gradient(top,  rgba(3,3,49,0.06) 0%,rgba(3,3,49,0.07) 19%,rgba(3,3,49,0.13) 46%,rgba(3,3,49,0.25) 76%,rgba(3,3,49,0.38) 100%);
        background: linear-gradient(to bottom,  rgba(3,3,49,0.06) 0%,rgba(3,3,49,0.07) 19%,rgba(3,3,49,0.13) 46%,rgba(3,3,49,0.25) 76%,rgba(3,3,49,0.38) 100%); 
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0f030331', endColorstr='#61030331',GradientType=0 );
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
    }
    .hero-banner-wrap {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 45.1%;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        z-index: 1;
        .hero-content {
            display: block;
            max-width: 1050px;
            margin: 0 auto;
            width: 100%;
            height: auto;
        }
        .hero-banner-cont {
            p {
                color: $white;
                font-size: rem-calc(82);
                margin: rem-calc(0 0 16 0);
                line-height: normal;
                strong {
                    font-weight: $mediumdark;
                }
                @include bp(lg) {
                    font-size: rem-calc(70);
                    margin: rem-calc(0 0 10 0);
                }
                @include bp(tabletXl) {
                    font-size: rem-calc(62);
                }
                @include bp(tabletsm) {
                    font-size: rem-calc(46);
                }
                @include bp(sm) {
                    font-size: rem-calc(40);
                }
            }
            h1 {
                color: $white;
                font-size: rem-calc(23);
                font-weight: $medium;
                line-height: normal;
                @include bp(lg) {
                    font-size: rem-calc(20);
                }
                @include bp(tabletXl) {
                    font-size: rem-calc(18);
                }
                @include bp(tabletsm) {
                    font-size: rem-calc(16);
                }
            }
        }
    }
}