.footer-cta {
    position: relative;
    padding: rem-calc(63 0 86);
    @include bp(tabletXl) {
        padding: rem-calc(40 0);
    }
    @include bp(tabletsm) {
        padding: rem-calc(30 0);
    }
    &.subscribe-form {
        padding: rem-calc(148 0 115);
        @include bp(tabletXl) {
            padding: rem-calc(40 0);
        }
        @include bp(tabletsm) {
            padding: rem-calc(30 0);
        }
        .footer-cta-cont {
            padding: 0 0 0 0;
            max-width: 800px;
            width: 100%;
            margin: 0 auto 0;
            p {
                font-size: rem-calc(20);
                line-height: 1.5;
            }
        }
        .footer-cta-form {
            max-width: 800px;
            width: 100%;
            margin: 0 auto 0;
            padding: 66px 4px 0px 0;
            @include bp(tabletsm) {
                padding: 30px 0px 0px 0;
            }
            .gform_wrapper {
                form {
                    position: relative;
                    .validation_error {
                        display: none;
                    }
                    .gform_body {
                        width: 98%;
                        ul {
                            li {
                                padding: 0;
                                &.gfield {
                                    input {
                                        padding: 11.8px 190px 11.8px 15px !important;
                                        @include bp(tabletsm) {
                                            padding: 11px 125px 11px 15px !important;
                                        }
                                    }
                                }
                                &.gfield_error {
                                    input {
                                        border-color: $red;
                                    }
                                }
                                .validation_message {
                                    display: none;
                                }
                            }
                        }
                    }
                    .gform_footer {
                        position: absolute;
                        right: 0;
                        top: 0;
                        margin: 0;
                        input[type=submit] {
                            background-color: $red;
                            color: $white;
                            padding: 10px 40px;
                            @include bp(tabletXl) {
                                padding: 12px 15px;
                                font-size: rem-calc(18);
                            }
                            @include bp(tabletsm) {
                                font-size: rem-calc(16);
                            }
                            &:hover {
                                background-color: $white;
                                color: $red;
                                border-color: $white;
                            }
                        }
                    }
                }
            }
        }
    }
    &:before {
        content: "";
        background-image: url(../images/footer-cta-pattern.png);
        background-position: left top;
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        z-index: 0;
    }
    .footer-cta-cont {
        padding: rem-calc(0 110 0 23);
        @include bp(lg) {
            padding: rem-calc(0 0 0 0);
        }
        h2 {
            color: $white;
            font-size: rem-calc(60);
            font-weight: $mediumdark;
            line-height: 1.2;
            margin-bottom: 10px;
            @include bp(lg) {
                font-size: rem-calc(50);
            }
            @include bp(tabletXl) {
                font-size: rem-calc(40);
            }
            @include bp(tabletsm) {
                font-size: rem-calc(34);
            }
        }
        p {
            color: $white;
        }
    }
    .footer-cta-form {
        padding: rem-calc(56 4 0 0);
        @include bp(tabletsm) {
            padding: 30px 0px 0px 0;
        }
        .gform_confirmation_message {
            color: $white;
            font-size: 22px;
        }
        .gform_wrapper {
            background-color: transparent;
            form {
                .validation_error {
                    color: $white;
                    font-size: rem-calc(18);
                    margin: rem-calc(0 0 20 0);
                    
                }
                .gform_body {
                    ul {
                        li {
                            margin: 0;
                            padding: rem-calc(0 0 15 0);
                            position: relative;
                            list-style-type: none;
                            > label {
                              display: none;
                            }
                            &:focus {
                                outline: none;
                            }
                            &.date-type {
                                input {
                                    background-image: url(../images/select-white-icon.svg);
                                    background-repeat: no-repeat;
                                    background-position: 93% center;
                                    background-size: 14px 12px;
                                    cursor: pointer;
                                    @include bp(tabletsm) {
                                        background-position: 95% center;
                                    }
                                    @include bp(xsm) {
                                        background-position: 97% center;
                                    }
                                }
                            }
                            &.location-type {
                                select {
                                    background-position: 93% center!important;
                                    @include bp(xsm) {
                                        background-position: 97% center!important;
                                    }
                                }
                            }
                            &.gfield {
                                &.col-50 {
                                    width: 50%;
                                    display: inline-block;
                                    padding: 0 10px 0;
                                    vertical-align: top;
                                    @include bp(xsm) {
                                        width: 100%;
                                        padding: rem-calc(0 0 15 0);
                                    }
                                    &:nth-last-child(2) {
                                        padding-left: 0;
                                    }
                                    &:last-child {
                                        padding-right: 0;
                                    }
                                }
                                input {
                                    background-color: transparent;
                                    border: 1px solid #fff;
                                    opacity: 1;
                                    box-shadow: none;
                                    -webkit-appearance: none;
                                    font-size: 17px !important;
                                    line-height: normal !important;
                                    padding: 16.8px 15px 14.8px 15px !important;
                                    color: #fff;
                                    width: 100% !important;
                                    margin: 0;
                                    min-width: inherit;
                                    &:focus {
                                        outline: none;
                                    }
                                }
                                select {
                                    background-color: transparent;
                                    border: 1px solid #fff;
                                    opacity: 1;
                                    box-shadow: none;
                                    -webkit-appearance: none;
                                    font-size: 17px !important;
                                    line-height: normal!important;
                                    padding: 16.8px 15px 14.8px 15px !important;
                                    color: #fff;
                                    width: 100% !important;
                                    margin: 0;
                                    cursor: pointer;
                                    background-image: url(../images/select-white-icon.svg);
                                    background-repeat: no-repeat;
                                    background-position: 98% center;
                                    background-size: 14px 12px;
                                    min-width: inherit;
                                    @include bp(xsm) {
                                        background-position: 97% center;
                                    }
                                    option {
                                        color: $gray;
                                    }
                                    &:focus {
                                        outline: none;
                                    }
                                }
                            }
                            .validation_message {
                                color: $white;
                                font-size: rem-calc(15);
                                line-height: normal;
                                margin: rem-calc(5 0 0 0);
                            }
                        }
                    }
                }
                .gform_footer {
                    padding: 0;
                    margin: rem-calc(37 0 0 0);
                    @include bp(tabletsm) {
                        margin: rem-calc(20 0 0 0);
                    }
                    @include bp(xsm) {
                        margin: rem-calc(5 0 0 0);
                    }
                    input[type=submit] {
                        background-color: $white;
                        color: $red;
                        font-weight: $mediumdark;
                        font-size: rem-calc(20);
                        margin: 0;
                        padding: rem-calc(8 35);
                        line-height: normal;
                        box-shadow: none;
                        -webkit-appearance: none;
                        border: 1px solid transparent;
                        opacity: 1;
                        @include transition(0.5s, all, ease-in-out);
                        @include bp(tabletsm) {
                            font-size: rem-calc(18);
                        }
                        &:hover {
                            border-color: $white;
                            background-color: transparent;
                            color: $white;
                        }
                        &:focus {
                            outline: none;
                            border-color: $white;
                            background-color: transparent;
                            color: $white;
                        }
                    }
                }
            }
        }
    }
}